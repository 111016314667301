import React from "react";

const NumbersSection = ({ taxMasterclass }) => {
  const data = [
    {
      img: "/Assets/Satisfaction.png",
      heading: "1200+",
      para: "Satisfied Clients",
    },
    {
      img: "/Assets/calls.png",
      heading: "2000+",
      para: "Advisory Calls",
    },
    {
      img: "/Assets/money-saved.png",
      heading: "11Cr+",
      para: "Saved till now",
    },
    {
      img: "/Assets/Satisfaction.png",
      heading: "Trusted by",
      para: taxMasterclass ? "Verified CAs" : "Investors",
    },
  ];

  return (
    <div className="numbers-section">
      <div className="numbers-section-content">
        <h2>Numbers that speak for themselves</h2>
        <div className="numbers-section-card-container">
          {data.map((item) => (
            <div key={item.heading} className="numbers-section-card">
              <div>
                <img className="numbers-section-img" src={item.img} alt="" />
                <h4>{item.heading}</h4>
                <p>{item.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NumbersSection;
