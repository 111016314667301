// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ripple-effect {
    position: relative;
    overflow: hidden;
}

.ripple {
    position: absolute;
    border-radius: 50%;
    background: rgba(198, 185, 185, 0.7);
    transform: scale(0);
    animation: ripple-animation 600ms linear;
    pointer-events: none;
}

@keyframes ripple-animation {
    to {
        transform: scale(4);
        opacity: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/RippleButton/RippleButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,oCAAoC;IACpC,mBAAmB;IACnB,wCAAwC;IACxC,oBAAoB;AACxB;;AAEA;IACI;QACI,mBAAmB;QACnB,UAAU;IACd;AACJ","sourcesContent":[".ripple-effect {\n    position: relative;\n    overflow: hidden;\n}\n\n.ripple {\n    position: absolute;\n    border-radius: 50%;\n    background: rgba(198, 185, 185, 0.7);\n    transform: scale(0);\n    animation: ripple-animation 600ms linear;\n    pointer-events: none;\n}\n\n@keyframes ripple-animation {\n    to {\n        transform: scale(4);\n        opacity: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
