import React from "react";
import "./tnc.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RippleButton from "../RippleButton/RippleButton";

const About = () => {
  
  const openPopup = () => {
    window.Calendly.showPopupWidget(
      "https://calendly.com/savingz/free-ca-call-clone"
    );
  };

  return (
    <>
      <div className="tnc_main">
        <div className="tnc">
          <h2>About Us</h2>

          <Row>
            <Col className="tnc_mainsec">
            <div className="tnc_leftsec">
                <h4>Savings made simple</h4>
                <p>
                  Don’t know where to start from? It's simple, click on that
                  button and wait for our call, let's take it step by step. We
                  are here to help people like you get rich and achieve their
                  goals.
                </p>
                <RippleButton onClick={openPopup} className="tnc_btn">
                  CONTACT US<i className="fi fi-rr-arrow-right arrow"></i>
                </RippleButton>
              </div>
              <div className="tnc_rightsec">
                <img src="./Assets/gandhiji.png" alt="" />
              </div>
            </Col>
            <Col className="tnc_secondsec"></Col>
          </Row>
          <div className="terms-and-conditions">
      <p>Simplifying Wealth, Taxes, and Investments for High-Salaried Individuals!</p>
      <p>Meet the extraordinary Savingz team dedicated to revolutionizing taxation and wealth management. Led by a visionary CEO and supported by a team with diverse expertise from renowned institutions like IITs and IIMs. Together, we empower you with a simplified, secure, and tailor-made tax filing experience. Trust Savingz, where collective expertise meets your financial aspirations.</p>
      <p>SAWINGZ SOLUTIONS PRIVATE LIMITED</p>
      <p>GSTIN: 29ABMCS3366F1ZX</p>
      
      <p>Registered Address: 62/1, 9th Main Rd, Stage 2, Eshwara Layout, Indiranagar, Bengaluru, Karnataka 560038</p>
      
    </div>
        </div>
      </div>
    </>
  )
}

export default About