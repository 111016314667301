import React from "react";
import Learn from "./Learn";

const LearnSection = ({ taxMasterclass }) => {
  const cardContent = taxMasterclass
    ? [
        {
          image: "/Assets/Goal Setting.png",
          time: "00 mins - 10 mins",
          heading: "Goal Setting",
          para: "You'll dive into the agenda of webinar as to why this webinar is needed.",
        },
        {
          image: "/Assets/New tax vs Old tax.png",
          time: "10 mins - 20 mins",
          heading: "New v/s old tax Regime",
          para: "On the basis of your salary bracket, decide which regime suits you the best to fulfil your tax obligations.",
        },
        {
          image: "/Assets/Exemptions vs deductions.png",
          time: "20 mins - 25 mins",
          heading: "Exemptions v/s Deductions",
          para: "Learn the core differences between the most confusing topic exemptions and deductions.",
        },
        {
          image: "/Assets/strucutred salary.png",
          time: "25 mins - 35 mins",
          heading: "Salary Structure and Components",
          para: "Understand the importance of Structured Salary and then build upon various salary components like Basic, HRA and LTA, etc.",
        },
        {
          image: "/Assets/deductions deep dive.png",
          time: "35 mins - 45 mins",
          heading: "Deep Dive into Deductions",
          para: "Build your knowledge about 80C, 80 CCD, 80D, 80TTA, 80E, 80G, 80GGC",
        },
        {
          image: "/Assets/Important.png",
          time: "45 mins - 55 mins",
          heading: "Most important information you shouldn't miss",
          para: "Cover Corners around Home Loans, Capital Asset Gain & Trading Assets!",
        },
        {
          image: "/Assets/QnA.png",
          time: "55 mins - 60 mins",
          heading: "Quick QnA",
          para: "Ask your doubts and get it solved live by your financial advisor!!!",
        },
      ]
    : [
        {
          image: "/Assets/Goal Setting.png",
          time: "00 mins - 10 mins",
          heading: "Goal Setting",
          para: "Begin by visualizing your financial aspirations and setting clear, achievable goals.",
        },
        {
          image: "/Assets/dreams.png",
          time: "10 mins - 20 mins",
          heading: "Real Dreams and Important Milestone Identification",
          para: "Identify your personal dreams and establish key milestones critical to your financial journey.",
        },
        {
          image: "/Assets/manage-finances.png",
          time: "20 mins - 30 mins",
          heading: "Managing Finances without Stable Income",
          para: "Learn effective strategies to manage your finances, even with irregular income.",
        },
        {
          image: "/Assets/investment-opportunities.png",
          time: "30 mins - 40 mins",
          heading: "Exploring Investment Opportunities",
          para: "Explore diverse avenues for investing your money wisely to foster growth.",
        },
        {
          image: "/Assets/risk-management.png",
          time: "40 mins - 50 mins",
          heading: "Optimal Investments and Risk Management",
          para: "Discover top investment choices and effective strategies for diversifying risk.",
        },
        {
          image: "/Assets/Important.png",
          time: "50 mins - 60 mins",
          heading: "Q&A Session",
          para: "Receive personalized answers to your questions on tax planning and investments.",
        },
      ];

  const heading = "What will you learn in 1 Hour?";

  return (
    <div className="learn-section">
      <div className="learn-heading_wrapper">
        <h2>{heading}</h2>
      </div>
      <div className="learn-card-wrapper">
        {cardContent.map((item, index) => (
          <Learn item={item} index={index} key={index} />
        ))}
      </div>
    </div>
  );
};

export default LearnSection;
