import axios from 'axios';

const sendWhatsAppMessage = (phoneNumber, taxableIncome, totalDeductions, totalTax) => {
  const url = `https://live-mt-server.wati.io/306923/api/v1/sendTemplateMessage?whatsappNumber=${phoneNumber}`;
  const apiKey = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjYWNiMTQ3Mi1lOGZhLTQ1NDAtOWNhZS1iMjNjY2Q1ZDZlYmIiLCJ1bmlxdWVfbmFtZSI6InBlYXJsYmxhY2szMDI4QGdtYWlsLmNvbSIsIm5hbWVpZCI6InBlYXJsYmxhY2szMDI4QGdtYWlsLmNvbSIsImVtYWlsIjoicGVhcmxibGFjazMwMjhAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDQvMjAvMjAyNCAwOToyMjoyNSIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMDY5MjMiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.P3e4N7yJaB7IkWq_kWIMih92IqYLtwrPuCzwFtZfiRg'; // Replace with your WATI API key

  const data = {
    parameters: [
      {
        name: 'taxincomelead',
        value: taxableIncome.toLocaleString(),
      },
      {
        name: 'deductionlead',
        value: totalDeductions.toLocaleString(),
      },
      {
        name: 'taxwebsitelead',
        value: totalTax.toLocaleString(),
      },
    ],
    template_name: 'websitetaxcalculator',
    broadcast_name: 'websitetaxcalculator',
  };

  axios.post(url, data, {
    headers: {
      'Authorization': apiKey,
      'Content-Type': 'application/json-patch+json',
    },
  })
  .then(response => {
    console.log('Message sent successfully:', response.data);
  })
  .catch(error => {
    console.error('Error sending message:', error);
  });
};

export default sendWhatsAppMessage;
