import React from "react";
import "./tnc.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RippleButton from "../RippleButton/RippleButton";

const MsmeInvestment = () => {
  const openPopup = () => {
    window.Calendly.showPopupWidget(
      "https://calendly.com/savingz/free-ca-call-clone"
    );
  };
  return (
    <>
      <div className="tnc_main">
        <div className="tnc">
          <h2>Msme Investment</h2>

          <Row>
            <Col className="tnc_mainsec">
              <div className="tnc_leftsec">
                <h4>Savings made simple</h4>
                <p>
                  Don’t know where to start from? It's simple, click on that
                  button and wait for our call, let's take it step by step. We
                  are here to help people like you get rich and achieve their
                  goals.
                </p>
                <RippleButton onClick={openPopup} className="tnc_btn">
                  CONTACT US<i className="fi fi-rr-arrow-right arrow"></i>
                </RippleButton>
              </div>
              <div className="tnc_rightsec">
                <img src="./Assets/gandhiji.png" alt="" />
              </div>
            </Col>
            <Col className="tnc_secondsec"></Col>
          </Row>
          <div className="terms-and-conditions">
            <p>
              We at Savingz assist Micro, Small & Medium Enterprises (MSMEs)
              with taxes and compliances as an end-to-end service.
            </p>

            <h3>Tax Advisory Services:</h3>
            <p>
              Savingz offers personalized tax advisory services to MSMEs to help
              them understand their tax obligations, compliance requirements,
              and available deductions. Savingz provides guidance on tax
              planning, including optimizing expenses, investments, and claiming
              eligible tax benefits.
            </p>

            <h3>Customized Tax Compliance Checklists:</h3>
            <p>
              Easy-to-follow checklists for tax compliance. Relevant support for
              filing GST returns, TDS payments, and other relevant tax forms.
            </p>

            <h3>Startup-Specific Tax Benefits:</h3>
            <p>
              Educate startups with tax incentives available to them. Highlight
              benefits like income tax holidays, capital gains exemptions, and
              simplified compliance procedures.
            </p>

            <h3>Educational Webinars and Workshops:</h3>
            <p>
              Savingz also organizes webinars and workshops specifically
              tailored for MSMEs that cover topics such as understanding GST,
              income tax, and tax-saving strategies.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MsmeInvestment;
