import React from "react";

const WhoCard = ({ heading, para, emoji }) => {
  return (
    <div className="who_card">
      <div className="who-card_html_embedded">
        <h3>{emoji}</h3>
      </div>
      <div className="who_card-point">
        <h3 className="point_heading">{heading}</h3>
        <p className="point_para">{para}</p>
      </div>
    </div>
  );
};

export default WhoCard;
