import React from "react";
import Review from "./Review";

const ReviewSlider = ({ reviews, desktopReviews }) => {
  return (
    <div className="review-slider">
      {desktopReviews[reviews].map((review, index) => (
        <Review review={review} key={index} />
      ))}
    </div>
  );
};

export default ReviewSlider;
