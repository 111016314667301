import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import RentReceipt from "./RentReceipt";
import { useReactToPrint } from "react-to-print";

const RentReceipts = ({ rentReceipts, values }) => {
  const receiptRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  const handleDownload = () => {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const margin = 20;
    const receiptHeight = 250; // Adjust based on the actual receipt height
    let positionY = margin;

    const addReceiptsToPDF = async () => {
      for (let i = 0; i < rentReceipts.length; i++) {
        const item = rentReceipts[i];
        const input = document.getElementById(`receipt_${item.receiptNo}`);

        await html2canvas(input, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");

          if (positionY + receiptHeight > pdfHeight - margin) {
            pdf.addPage();
            positionY = margin;
          }

          pdf.addImage(
            imgData,
            "PNG",
            margin,
            positionY,
            pdfWidth - 2 * margin,
            receiptHeight
          );
          positionY += receiptHeight + margin;
        });
      }
      pdf.save("rent-receipts.pdf");
    };

    addReceiptsToPDF().catch((error) => {
      console.error("Error generating PDF: ", error);
    });
  };

  const formatDate = (date) => {
    const options = {
      month: "numeric",
      day: "numeric",
      year: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <>
      <div id="rent_receipts" className="rent_receipt_buttons_container">
        <button className="printButton" onClick={handleDownload}>
          Download Receipts
        </button>
        <button className="printButton" onClick={handlePrint}>
          Print Receipts
        </button>
      </div>
      <div
        ref={receiptRef}
        style={{ margin: "0 auto" }}
        className="rent_receipt_container"
        id="rent_receipt_container"
      >
        {rentReceipts.map((item) => (
          <div key={item.receiptNo}>
            <header className="print-header">
              {/* <p className="print_date"></p> */}
              <p className="print_detail">
                Your House Rent Receipts<span>{formatDate(new Date())}</span>
              </p>
            </header>
            <RentReceipt
              rentReceiptsLength={rentReceipts.length}
              key={item.receiptNo}
              name={values.name}
              houseOwnerName={values.houseOwnerName}
              houseOwnerPan={values.houseOwnerPan}
              address={values.address}
              startDate={item.receiptDate}
              endDate={item.nextDate}
              receiptNo={item.receiptNo}
              rent={item.rent}
            />
            <footer className="print-footer">https://savingz.in</footer>
          </div>
        ))}
      </div>
    </>
  );
};

export default RentReceipts;
