import React from "react";

import { useMedia } from "react-use";
import DesktopReviews from "./DesktopReviews";
import MobileReviews from "./MobileReviews";

const ReviewsSection = () => {
  const isMobile = useMedia("(max-width: 475px)");
 
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1950,
    cssEase: "linear",
    boxShadow: "none"
  };

  return (
    <div className="reviews-section">
      <div>
        <h1>Read Reviews on LinkedIn</h1>
        <div className="hand-drawn-line"></div>
      </div>
      {!isMobile ? (
        <DesktopReviews settings={settings} />
      ) : (
        <MobileReviews settings={settings} />
      )}
    </div>
  );
};

export default ReviewsSection;
