import React, { useState, useEffect } from "react";
import "./banner.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import bgimg from "../Assets/bannerback.png";
import AlternateText from "../Effects/AlternateText";
import downld from "./Vector.png";
import link from "./link.png";
import one from "./1.png";
import two from "./2.png";
import three from "./0003.png";
import four from "./0004.png";
import iphone from "./iphone.png";
import linkblack from "./link_black.png";
import Model from "../Model/Model";
import PhoneNumberDialog from "./PhoneNumberDialog";
import sendWhatsAppMessage from "./sendWhatsAppMessage";
import { useModal } from "../ModalContext";
import { InlineWidget } from "react-calendly";

const Banner = () => {
  const [imgSrc, setImgSrc] = useState(link); // Initial image source
  const [modalShow, setModalShow] = useState(false); // State to control modal visibility
  const [dialogShow, setDialogShow] = useState(false); // State to control dialog visibility
  const modalRef = useModal(); // Use context for the modal

  const handleMouseOver = () => {
    setImgSrc(linkblack); // Change the image source on mouse over
  };

  const handleMouseOut = () => {
    setImgSrc(link); // Revert back to original image source on mouse out
  };

  const handleSendAppLinkClick = () => {
    setDialogShow(true);
  };

  const handleSend = (phoneNumber) => {
    sendWhatsAppMessage(phoneNumber);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (modalShow || dialogShow) {
      body.classList.add("blur-background");
    } else {
      body.classList.remove("blur-background");
    }
    return () => {
      body.classList.remove("blur-background");
    };
  }, [modalShow, dialogShow]);

  const divStyle = {
    backgroundImage: `linear-gradient(to right, rgba(117, 17, 211, 0.8), rgba(105, 26, 111,0.8)), url(${bgimg})`, // Use the imported image
    backgroundSize: "cover",
    backgroundPosition: "left",
  };

  const openTypeformDialog = () => {
    if (modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }

    const modal = document.createElement("div");
    modal.classList.add("typeform-dialog");
    modalRef.current = modal; // Store reference to the modal

    const iframe = document.createElement("iframe");
    iframe.src = "https://kn06djn2bvg.typeform.com/to/CsAd32j8";
    iframe.width = "100%";
    iframe.height = "500px";
    iframe.frameBorder = "0";
    iframe.allow = "fullscreen";

    const closeButton = document.createElement("button");
    closeButton.innerText = "X";
    closeButton.classList.add("close-button");

    modal.appendChild(closeButton);
    modal.appendChild(iframe);
    document.body.insertBefore(modal, document.body.firstChild);

    const closeModal = () => {
      document.body.removeChild(modal);
      modalRef.current = null; // Clear reference to the modal
    };

    modal.addEventListener("click", (event) => {
      if (event.target === modal || event.target === closeButton) {
        closeModal();
      }
    });
  };

  const openPopup = () => {
    // Function to open the Calendly popup
    window.Calendly.showPopupWidget(
      "https://calendly.com/savingz/free-ca-call-clone"
    );
  };

  return (
    <>
      <div className="banner_main_container">
        <div style={divStyle} className="banner_container">
          <Container>
            <Row>
              <Col sm={7}>
                <div className="banner_text">
                  <button className="wealth_btn">WEALTH SIMPLIFIED</button>
                </div>
                <div className="banner_design_text">
                  <span className="main_span">
                    Manage your taxes with expert CA advisors.
                  </span>
                  {/* <AlternateText words={["Taxes", "Wealth"]} interval={2000} /> */}
                </div>
                {/* <div className="banner_design_text">
                  <span className="main_span">With </span>
                  <AlternateText
                    words={["India's Best", "Data Driven"]}
                    interval={2000}
                  />
                </div>
                <div className="banner_design_text">
                  <h2>
                    <span className="typed-text_span">0</span>
                    <AlternateText
                      words={["Artificial Intelligence", "Financial Advisor"]}
                      interval={2000}
                    />
                  </h2>
                </div> */}
                <div className="banner_pera">
                  <p>
                    Maximize your financial potential with expert guidance and
                    cutting-edge AI technology, tailored specifically for the
                    Indian market
                  </p>
                </div>

                <div className="banner_btn_div">
                  <button className="dn_btn" onClick={openTypeformDialog}>
                    FREE CA CALL{" "}
                    <img
                      src={downld}
                      alt="Inr png"
                      style={{ marginLeft: "5px" }}
                    />
                  </button>
                  <button
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onClick={handleSendAppLinkClick} // Open dialog on button click
                    className="dn_btn_two"
                  >
                    Send App Link
                    <img
                      src={imgSrc}
                      alt="Inr png"
                      style={{ marginLeft: "5px" }}
                    />
                  </button>
                </div>
                <div className="four_button_div">
                  <h2>What You'll Gain?</h2>
                </div>
                <div className="banner_four_btn_main">
                  <button className="banner_buttons">
                    <img
                      src={one}
                      alt="Inr png"
                      style={{ marginLeft: "5px" }}
                    />{" "}
                    Maximize Tax Savings
                  </button>
                  <button className="banner_buttons">
                    <img
                      src={two}
                      alt="Inr png"
                      style={{ marginLeft: "5px" }}
                    />{" "}
                    Wealth Management Insights
                  </button>
                </div>
                <div className="banner_four_btn_main_two">
                  <button className="banner_buttons">
                    <img
                      src={three}
                      alt="Inr png"
                      style={{ marginLeft: "5px" }}
                    />{" "}
                    Achieve 25% Average Returns
                  </button>
                  <button className="banner_buttons">
                    <img
                      src={four}
                      alt="Inr png"
                      style={{ marginLeft: "5px" }}
                    />{" "}
                    Expert Guidance on Finance
                  </button>
                </div>
              </Col>
              <Col className="hero-calendly" sm={5}>
                {/* <img className="customimage" src={iphone} alt="Iphone" /> */}
                <div style={{ borderRadius: "32px", overflow: "hidden" }}>
                  <InlineWidget url="https://calendly.com/savingz/free-ca-call-clone" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {/* Modal Component */}
      <Model show={modalShow} onHide={() => setModalShow(false)} />

      {/* Phone Number Dialog */}
      <PhoneNumberDialog
        show={dialogShow}
        onHide={() => setDialogShow(false)}
        onSend={handleSend}
      />
      {(modalShow || dialogShow) && <div className="blur-background"></div>}
    </>
  );
};

export default Banner;
