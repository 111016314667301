import InputBox from "./InputBox";

const Form2 = ({
  backwardIndex,
  values,
  valuesDispatch,
  errors,
  errorsDispatch,
  generateRentReceipts,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    errorsDispatch({ type: "NAME", value: "" });
    errorsDispatch({ type: "NUMBER", value: "" });
    errorsDispatch({ type: "HOUSEOWNERNAME", value: "" });
    errorsDispatch({ type: "HOUSEOWNERPAN", value: "" });
    errorsDispatch({ type: "STARTDATE", value: "" });
    errorsDispatch({ type: "ENDDATE", value: "" });

    if (!values.name) {
      errorsDispatch({ type: "NAME", value: "Name is required!" });
      return;
    }

    if (values.name.length > 30) {
      errorsDispatch({
        type: "NAME",
        value: "Name shouldn't contain more than 30 characters!",
      });
      return;
    }

    if (!Number(values.number) || values.number.length !== 10) {
      errorsDispatch({
        type: "NUMBER",
        value: "Please enter a valid phone number!",
      });
      return;
    }

    if (!values.houseOwnerName) {
      errorsDispatch({
        type: "HOUSEOWNERNAME",
        value: "House owner's name is required!",
      });
      return;
    }

    if (values.houseOwnerName.length > 30) {
      errorsDispatch({
        type: "HOUSEOWNERNAME",
        value: "House owner's name shouldn't contain more than 30 characters!",
      });
      return;
    }

    if (values.houseOwnerPan && values.houseOwnerPan.length !== 10) {
      errorsDispatch({
        type: "HOUSEOWNERPAN",
        value: "Enter a valid pan number!",
      });
      return;
    }

    if (!values.startDate) {
      errorsDispatch({
        type: "STARTDATE",
        value: "Please enter a start date!",
      });
      return;
    }

    if (!values.endDate) {
      errorsDispatch({
        type: "ENDDATE",
        value: "Please enter an end date!",
      });
      return;
    }

    if (new Date(values.startDate) > new Date(values.endDate)) {
      errorsDispatch({
        type: "ENDDATE",
        value: "End date shouldn't be smaller than start date!",
      });
      return;
    }

    generateRentReceipts();
  };

  return (
    <form className="form2 form" onSubmit={handleSubmit}>
      <div className="inputContainer">
        <InputBox
          label="What is your name?"
          id="name"
          inputValue={values.name}
          setInputValue={(value) => valuesDispatch({ type: "NAME", value })}
          inputError={errors.name}
          setInputError={(value) => errorsDispatch({ type: "NAME", value })}
          type="text"
        />
        <InputBox
          label="What is your phone number?"
          id="number"
          inputValue={values.number}
          setInputValue={(value) => valuesDispatch({ type: "NUMBER", value })}
          inputError={errors.number}
          setInputError={(value) => errorsDispatch({ type: "NUMBER", value })}
          type="text"
        />
      </div>
      <div className="inputContainer">
        <InputBox
          label="What is the name of your house owner?"
          id="houseOwnerName"
          inputValue={values.houseOwnerName}
          setInputValue={(value) =>
            valuesDispatch({ type: "HOUSEOWNERNAME", value })
          }
          inputError={errors.houseOwnerName}
          setInputError={(value) =>
            errorsDispatch({ type: "HOUSEOWNERNAME", value })
          }
          type="text"
        />
        <InputBox
          label="What is your House Owner's PAN no (optional)?"
          id="houseOwner"
          inputValue={values.houseOwnerPan}
          setInputValue={(value) =>
            valuesDispatch({ type: "HOUSEOWNERPAN", value })
          }
          inputError={errors.houseOwnerPan}
          setInputError={(value) =>
            errorsDispatch({ type: "HOUSEOWNERPAN", value })
          }
          type="text"
        />
      </div>
      <div className="inputContainer">
        <InputBox
          label="From when would you like to generate the rent receipt?"
          id="startDate"
          inputValue={values.startDate}
          setInputValue={(value) =>
            valuesDispatch({ type: "STARTDATE", value })
          }
          inputError={errors.startDate}
          setInputError={(value) =>
            errorsDispatch({ type: "STARTDATE", value })
          }
          type="date"
        />
        <InputBox
          label="Till when would you like to generate the receipt?"
          id="endDate"
          inputValue={values.endDate}
          setInputValue={(value) => valuesDispatch({ type: "ENDDATE", value })}
          inputError={errors.endDate}
          setInputError={(value) => errorsDispatch({ type: "ENDDATE", value })}
          type="date"
        />
      </div>
      <div className="form2Buttons">
        <button onClick={backwardIndex} className="goBackButton" type="button">
          Go Back
        </button>
        <button className="submitButton" type="submit">
          Generate Receipt
        </button>
      </div>
    </form>
  );
};

export default Form2;
