import React from "react";

import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import ReviewSlider from "./ReviewSlider";

const desktopReviews = {
  reviews1: [
    {
      reviewerName: "Asmita Mandal",
      reviewerImage: "./Assets/asmita.jpg",
      position: "Senior HR, Accenture",
      review:
        "Saved additional 1L in taxes with Savingz, since last 5 yrs I used to file tax on my own. CA's on Savingz are smart and have reasonable knowledge. Excellent customer support and super friendly tax filing.",
      rating: "4.8",
    },
    {
      reviewerName: "Sriram Sarad Samoju",
      reviewerImage: "./Assets/sarad.jpg",
      position: "Product Designer, Zoomcar",
      review:
        "Savingz transformed my tax planning. Their expertise helped me optimize my deductions and save significantly. Highly recommended!",
      rating: "5",
    },
    {
      reviewerName: "Aayush Chaudhary",
      reviewerImage: "./Assets/aayush.jpg",
      position: "Senior AI Engineer, Dream11",
      review:
        "I'm grateful for Savingz's exceptional customer service. They were responsive, professional, and went above and beyond to address my tax-related queries.",
      rating: "4.9",
    },
    {
      reviewerName: "Surendra Sheshma",
      reviewerImage: "./Assets/surendra.jpg",
      position: "SDE II, Beepkart",
      review:
        "Choosing Savingz was a game-changer for me. Their comprehensive understanding of tax laws and attention to detail saved me a significant amount of money.",
      rating: "4.2",
    },
  ],
  reviews2: [
    {
      reviewerImage: "./Assets/piyush.jpg",
      reviewName: "Piyush Chittara",
      position: "CEO, StreamNFT",
      review:
        "Great initiative, helped understand claimable benefits and save taxes 🙌 Loved the team and prompt reverts.",
      rating: "5",
    },
    {
      reviewerImage: "./Assets/sourabrata.jpg",
      reviewName: "Sourabrata Samanta",
      position: "SDE II, Accenture",
      review:
        "This is the first time I got this much return, thanks to Savingz team. I didn't even knew I can get this much.",
      rating: "4.3",
    },
    {
      reviewerImage: "./Assets/reema.jpg",
      reviewName: "Reema Singla",
      position: "Lead Analyst, Fidelity Investment",
      review:
        "Recently did my first investment through the app and I loved their personalised walkthrough at all steps. Waiting for my form 16 this year and I will also file it through you guys",
      rating: "5",
    },
    {
      reviewerImage: "./Assets/pingal.jpg",
      reviewerName: "Pingal Kakati",
      position: "Lead Designer, Razorpay",
      review:
        "I'm impressed with Savingz's personalized approach. They analyzed my unique financial situation and provided tailored tax strategies that saved me a substantial amount.",
      rating: "4",
    },
  ],
  reviews3: [
    {
      reviewerImage: "./Assets/wakar.jpg",
      reviewerName: "Wakar Khan",
      position: "SDE III, Swiggy",
      review:
        "Savingz exceeded my expectations. Their knowledgeable CAs provided valuable insights, helping me navigate complex tax regulations and maximize my savings",
      rating: "4.6",
    },
    {
      reviewerImage: "./Assets/sarthak.jpg",
      reviewerName: "Sarthak Agarwal",
      position: "Software Engineer, Bloomberg",
      review:
        "I’ve been using Savingz for the past year, and it’s been a game-changer! As an NRI, navigating the complex Indian tax filing was nightmare, But these people took care of it",
      rating: "4.7",
    },
  ],
};

const DesktopReviews = ({ settings }) => {
  return (
    <div className="reviews-slider">
      <Slider {...settings}>
        {Object.keys(desktopReviews).map((reviews, index) => {
          return (
            <div key={index}>
              <ReviewSlider desktopReviews={desktopReviews} reviews={reviews} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default DesktopReviews;
