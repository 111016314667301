import React, { useState } from "react";
import "./RippleButton.css";

const RippleButton = ({ children, onClick, className}) => {
  const [ripples, setRipples] = useState([]);

  const handleRippleClick = (event) => {
    // Optionally add passed onClick functionality
    if (onClick) onClick(event);

    const button = event.currentTarget;
    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;
    const newRipple = { x, y, size };

    setRipples((currentRipples) => [...currentRipples, newRipple]);

    setTimeout(() => {
      setRipples((currentRipples) => currentRipples.slice(1));
    }, 600); // animation duration
  };

  return (
    <button
      className={`ripple-effect ${className}`}
      onClick={handleRippleClick}
    >
      {children}
      {ripples.map((ripple, index) => (
        <span
          key={index}
          style={{
            top: ripple.y,
            left: ripple.x,
            width: ripple.size,
            height: ripple.size,
          }}
          className="ripple"
        />
      ))}
    </button>
  );
};

export default RippleButton;
