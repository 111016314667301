// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Hide FeatureSlider on screens wider than 768px */
@media (min-width: 769px) {
    .mobile-only {
      display: none;
    }
  }
  
  /* Hide Features on screens narrower than 769px */
  @media (max-width: 768px) {
    .desktop-only {
      display: none;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,mDAAmD;AACnD;IACI;MACE,aAAa;IACf;EACF;;EAEA,iDAAiD;EACjD;IACE;MACE,aAAa;IACf;EACF","sourcesContent":["/* Hide FeatureSlider on screens wider than 768px */\n@media (min-width: 769px) {\n    .mobile-only {\n      display: none;\n    }\n  }\n  \n  /* Hide Features on screens narrower than 769px */\n  @media (max-width: 768px) {\n    .desktop-only {\n      display: none;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
