import React from "react";
import "./tnc.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RippleButton from "../RippleButton/RippleButton";

const Tnc = () => {
  const openPopup = () => {
    window.Calendly.showPopupWidget(
      "https://calendly.com/savingz/free-ca-call-clone"
    );
  };
  return (
    <>
      <div className="tnc_main">
        <div className="tnc">
          <h2>Terms and conditions</h2>

          <Row>
            <Col className="tnc_mainsec">
              <div className="tnc_leftsec">
                <h4>Savings made simple</h4>
                <p>
                  Don’t know where to start from? It's simple, click on that
                  button and wait for our call, let's take it step by step. We
                  are here to help people like you get rich and achieve their
                  goals.
                </p>
                <RippleButton onClick={openPopup} className="tnc_btn">
                  CONTACT US<i className="fi fi-rr-arrow-right arrow"></i>
                </RippleButton>
              </div>
              <div className="tnc_rightsec">
                <img src="./Assets/gandhiji.png" alt="" />
              </div>
            </Col>
            <Col className="tnc_secondsec"></Col>
          </Row>
          <div className="terms-and-conditions">
      <p>Welcome to Savingz!</p>
      <p>These Terms and Conditions ("Terms") govern your use of our website (www.savingz.in) and any related products or services (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Services.</p>
      
      <p>1. Introduction</p>
      <p>Welcome to Savingz! These Terms and Conditions ("Terms") govern your use of our website (www.savingz.in) and any related products or services (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Services.</p>
      
      <p>2. Use of Services</p>
      <p>a. Eligibility: By using our Services, you represent that you are at least 18 years old and have the legal capacity to enter into a binding agreement. If you are using our Services on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.</p>
      <p>b. Registration: In order to access certain features of our Services, you may need to create an account. You agree to provide accurate, complete, and up-to-date information during the registration process and to keep your account credentials confidential.</p>
      <p>c. Restrictions: You agree not to use our Services for any unlawful or prohibited purpose. You shall not engage in any activity that could damage, disable, overburden, or impair our website or interfere with any other party's use and enjoyment of our Services.</p>

      <p>3. Intellectual Property</p>
      <p>a. Ownership: All content, materials, and intellectual property rights on our website are owned by Savingz or our licensors. You may not reproduce, distribute, modify, or create derivative works of any content on our website without our prior written consent.</p>
      <p>b. Trademarks: The Savingz name and logo are trademarks of Savingz. You may not use these trademarks without our express written permission.</p>

      <p>4. Payment and Billing</p>
      <p>a. Fees: Some of our Services may require payment of fees. You agree to pay all fees as specified and in accordance with the payment terms provided to you. All fees are non-refundable.</p>
      <p>b. Billing Information: You must provide accurate billing information, such as your billing address and payment details. By providing such information, you authorize us to charge the specified fees to your chosen payment method.</p>

      <p>5. Disclaimer of Warranty</p>
      <p>Our Services are provided on an "as is" and "as available" basis, without any warranties or representations of any kind, whether express, implied, or statutory. We do not guarantee that our Services will be error-free, secure, or uninterrupted.</p>

      <p>6. Limitation of Liability</p>
      <p>To the fullest extent permitted by law, Savingz and its affiliates, officers, directors, employees, and agents shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our Services.</p>

      <p>7. Indemnification</p>
      <p>You agree to indemnify and hold harmless Savingz and its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses (including attorneys' fees) arising out of or in connection with your use of our Services or any violation of these Terms.</p>

      <p>8. Changes to these Terms</p>
      <p>We may update these Terms from time to time to reflect changes in our practices or for legal, operational, or regulatory reasons. We encourage you to review this page regularly to stay informed about any updates.</p>

      <p>9. Governing Law</p>
      <p>These Terms shall be governed by and construed in accordance with the laws of [Insert Governing Jurisdiction], without regard to its conflict of law provisions.</p>

      <p>10. Contact Us</p>
      <p>If you have any questions, concerns, or requests related to these Terms, please contact us at hi@sawingz.com. We will do our best to address your queries and resolve any issues promptly.</p>
    </div>
        </div>
      </div>
    </>
  );
};

export default Tnc;
