import "./App.css";
import React from "react";
import "./main.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navebar from "./Navebar/Navebar";
// import CompaneysCrousel from "./TrustedCompaneys/CompaneysCrousel";
// import WhyCooseUs from "./WhyChoose/WhyCooseUs";
// import LeadingMedia from "./LeadingMedia/LeadingMedia";
// import Stats from "./Stats/Stats";
// import Team from "./Team/Team";
// import Features from "./Features/Features";
// import Testimonials from "./Testimonials/Testimonials";
import Tnc from "./Pages/Tnc";
// import Banner from "./Banner/Banner";
// import ContactUS from "./ContactUs/ContactUS";
import Footertwo from "./Footer/Footertwo";
import OurTeam from "./Pages/OurTeam";
// import FeatureSlider from "./FeatureSlider/FeatureSlider";
import About from "./Pages/About";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Cancellation from "./Pages/Cancellation";
import WealthManagement from "./Pages/WealthManagement";
import ItrFiling from "./Pages/ItrFiling";
import AlternateInvestment from "./Pages/AlternateInvestment";
import MsmeInvestment from "./Pages/MsmeInvestment";
import RealEstate from "./Pages/RealEstate";
import ReactGA from "react-ga";
// import Taxcal from "./Taxcal/Taxcal";
import RentReceipt from "./Pages/RentReceipt";
import { Toaster } from "react-hot-toast";
import Masterclass from "./Pages/Masterclass";
import MasterclassRegisterSuccess from "./Pages/MasterclassRegisterSuccess";
import EquityWebinar from "./Pages/EquityWebinar";
// import Pricing from "./Pricing";
// import MasterclassPayment from "./Common/Masterclass/MasterclassPayment";
import HomePage from "./Pages/HomePage";

ReactGA.initialize("G-8GMC4JM7GX");

function App() {
  const hostname = window.location.hostname;

  // Redirect logic for main domain to subdomain
  if (hostname === "savingz.in") {
    window.location.replace(
      `https://www.${hostname}${window.location.pathname}`
    );
    return null; // Return null to prevent rendering anything while redirecting
  }

  return (
    <>
      <Router>
        <div>
          <Navebar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/terms-and-conditions" element={<Tnc />} />
            <Route path="/team-page" element={<OurTeam />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cancellation" element={<Cancellation />} />
            <Route path="/wealth-management" element={<WealthManagement />} />
            <Route path="/itr-filing" element={<ItrFiling />} />
            <Route
              path="/alternative-investment"
              element={<AlternateInvestment />}
            />
            <Route path="/msme-investment" element={<MsmeInvestment />} />
            <Route path="/real-estate" element={<RealEstate />} />
            <Route path="/rent-receipt" element={<RentReceipt />} />
            <Route path="/masterclass" element={<Masterclass />} />
            <Route path="/equity-masterclass" element={<EquityWebinar />} />
            <Route
              path="/register-success"
              element={<MasterclassRegisterSuccess />}
            />
          </Routes>
          <Footertwo />
        </div>
      </Router>
      <Toaster />
    </>
  );
}

export default App;
