import axios from "axios";
import toast from "react-hot-toast";

export const validateInputs = (values, errorsDispatch) => {
  errorsDispatch({ type: "NAME", value: "" });
  errorsDispatch({ type: "NUMBER", value: "" });
  errorsDispatch({ type: "EMAIL", value: "" });

  const isEmailValid =
    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(values.email) &&
    values.email.trim() !== "";

  if (!values.name) {
    errorsDispatch({ type: "NAME", value: "Name is required!" });
    return null;
  }

  if (!isEmailValid) {
    errorsDispatch({
      type: "EMAIL",
      value: "Invalid Email!",
    });
    return null;
  }

  if (!Number(values.number) || values.number.length !== 10) {
    errorsDispatch({
      type: "NUMBER",
      value: "Invalid Phone Number!",
    });
    return null;
  }
  return true;
};

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const displayRazorpay = async ({
  pricingAmount,
  navigate,
  name,
  email,
  contact,
  campaignId,
  successText,
  successHeading,
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    toast.error("Razorpay SDK failed to load. Are you online?");
    navigate("/masterclass?payment=true");
    return;
  }

  const data = {
    amount: pricingAmount * 100,
    email,
    number: contact,
    campaignId,
    name,
  };

  const result = await axios.post(
    "https://seahorse-app-69qkl.ondigitalocean.app/payment-order",
    data
  );

  if (!result) {
    toast.error("Server error. Are you online?");
    return;
  }

  const { paymentId, amount, id: order_id, currency } = result.data;
  handlePayment({
    amount,
    order_id,
    currency,
    campaignId,
    name,
    email,
    contact,
    paymentId,
    successText,
    navigate,
    successHeading,
  });
};

const handlePayment = ({
  amount,
  order_id,
  currency,
  campaignId,
  contact,
  email,
  name,
  paymentId,
  successText,
  successHeading,
  navigate,
}) => {
  const options = {
    key: "rzp_live_tjM6GmV4DXvMpl", // Enter the Key ID generated from the Dashboard
    amount: amount,
    currency: currency,
    name: "Savingz.",
    description: "Masterclass",
    image: "",
    order_id: order_id,
    handler: async function (response) {
      try {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          amount,
          name,
          email,
          campaignId,
          number: contact,
          paymentId,
        };

        await axios.post(
          "https://seahorse-app-69qkl.ondigitalocean.app/payment-success",
          data
        );

        toast.success("You've registered successfully!");
        navigate("/register-success", {
          state: {
            from: "masterclass",
            to: "free",
            text: successText,
            heading: successHeading,
          },
        });
      } catch (error) {
        const err = error?.response?.data?.detail || "Something went wrong!";
        toast.error(err);
      }
    },
    prefill: {
      name,
      email,
      contact,
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};

export const handleFreeClient = async ({
  campaignId,
  email,
  number,
  name,
  navigate,
  successText,
  successHeading,
}) => {
  const clientData = { campaignId, email, number, name };
  try {
    const { data } = await axios.post(
      "https://seahorse-app-69qkl.ondigitalocean.app/masterclass-client-details",
      clientData
    );

    toast.success(data.msg);
    navigate("/register-success", {
      state: {
        from: "masterclass",
        to: "free",
        text: successText,
        heading: successHeading,
      },
    });
  } catch (error) {
    const err = error?.response?.data?.detail
      ? error?.response?.data?.detail
      : error?.response?.detail
      ? error?.response?.detail
      : "Something went wrong!";
    toast.error(err);
  }
};
