import React, { useState } from "react";
import "./features.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Model from "../Model/Model";
import finance from "./finance.png";
import business from "./Business.png";
import fraction from "./fractions.png";
import intrest from "./interest-rate.png";
import tex from "./tax.png";
import wealth from "./wealth.png";
import pattern from "./pattern.png";

const Features = () => {
  // State for the current image and the active h3
  const [currentImage, setCurrentImage] = useState("./Assets/1.png");
  const [activeH3, setActiveH3] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);

  // Function to change the image and set active h3
  const changeImage = (image, index) => {
    setCurrentImage(image);
    setActiveH3(index);
  };

  return (
    <>
      <Model show={modalShow} onHide={() => setModalShow(false)} />
      <div className="features_main">
        <div className="features">
          <Row className="why_choose_row">
            <Col sm={12}>
              <h2 className="why_choose_heading">Our features</h2>
              <p className="why_choose_para">
                Because Success Deserves a Partner, Everything you need in one
                app
              </p>
            </Col>
            {/* <Col sm={4} className="text-sm-end">
            <RippleButton
                onClick={() => setModalShow(true)}
                className="whychoose_btn"
              >
                DOWNLOAD THE APP<i className="fi fi-rr-arrow-right arrow"></i>
              </RippleButton>
            </Col> */}
          </Row>
          <Container>
            <Row>
              <Col sm={3}>
                <div
                  className={`features_item_main ${
                    activeH3 === 1 ? "active" : ""
                  }`}
                  onMouseEnter={() => changeImage("./Assets/1.png", 1)}
                >
                  <h3 style={{ fontSize: "0rem" }}>
                    1{" "}
                    <img
                      className="feature_image"
                      src={fraction}
                      alt="wealth"
                    />
                  </h3>
                  <h4>Invest in Fractions</h4>
                  <p>
                    Get industry leading returns without emptying your emergency
                    fund
                  </p>
                </div>
                <div
                  className={`features_item_main ${
                    activeH3 === 2 ? "active" : ""
                  }`}
                  onMouseEnter={() => changeImage("./Assets/4.png", 2)}
                >
                  <h3 style={{ fontSize: "0rem" }}>
                    2{" "}
                    <img className="feature_image" src={intrest} alt="wealth" />
                  </h3>
                  <h4>Earn interest</h4>
                  <p>
                    Even on your referrals so you make money together, as it is
                    supposed to be
                  </p>
                </div>
                <div
                  className={`features_item_main ${
                    activeH3 === 3 ? "active" : ""
                  }`}
                  onMouseEnter={() => changeImage("./Assets/2.png", 3)}
                >
                  <h3 style={{ fontSize: "0rem" }}>
                    3 <img className="feature_image" src={tex} alt="texas" />
                  </h3>
                  <h4>Taxes, US and AI</h4>
                  <p>
                    Optimize your tax returns with AI and India’s best financial
                    advisors
                  </p>
                </div>
              </Col>
              <Col
                sm={6}
                className="d-flex justify-content-center mid_image_bg"
              >
                <div
                  className="features_img"
                  style={{
                    backgroundImage: `url(${pattern})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <img src={currentImage} alt="Feature Description" />
                </div>
              </Col>

              <Col sm={3}>
                <div
                  className={`features_item_main_right ${
                    activeH3 === 4 ? "active" : ""
                  }`}
                  onMouseEnter={() => changeImage("./Assets/3.png", 4)}
                >
                  <h3 style={{ fontSize: "0rem" }}>
                    4{" "}
                    <img
                      className="feature_image"
                      src={business}
                      alt="wealth"
                    />
                  </h3>
                  <h4>Invest in business</h4>
                  <p>
                    Choose from the exclusive drop every week and get a chance
                    to invest
                  </p>
                </div>
                <div
                  className={`features_item_main_right ${
                    activeH3 === 5 ? "active" : ""
                  }`}
                  onMouseEnter={() => changeImage("./Assets/5.png", 5)}
                >
                  <h3 style={{ fontSize: "0rem" }}>
                    5{" "}
                    <img className="feature_image" src={finance} alt="wealth" />
                  </h3>
                  <h4>Finance Delivered!</h4>
                  <p>
                    Get handpicked juiciest finance news everyday with your
                    morning coffee
                  </p>
                </div>
                <div
                  className={`features_item_main_right ${
                    activeH3 === 6 ? "active" : ""
                  }`}
                  onMouseEnter={() => changeImage("./Assets/6.png", 6)}
                >
                  <h3 style={{ fontSize: "0rem" }}>
                    6{" "}
                    <img className="feature_image" src={wealth} alt="wealth" />
                  </h3>
                  <h4>Wealth Simplified!</h4>
                  <p>
                    Exclusive access to save tax, do investment and much more
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Features;
