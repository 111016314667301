import Marquee from "react-fast-marquee";

const FeaturedOnSection = () => {
  return (
    <div className="featured-on-section">
        <h4>Featured on</h4>
      <div className="featured-on-container">
        <Marquee speed={100}>
          <div className="featured_on_img">
            <img src="./Assets/abp.png" alt="abpnews-savingz" />
          </div>
          <div className="featured_on_img">
            <img
              src="./Assets/republicnewstoday.png"
              alt="republicnews-savingz"
            />
          </div>
          <div className="featured_on_img">
            <img
              src="./Assets/businessstandard.png"
              alt="businessstandard-savingz"
            />
          </div>
          <div className="featured_on_img">
            <img
              src="./Assets/entrepreneurview.png"
              alt="entrepreneurview-savingz"
            />
          </div>
          <div className="featured_on_img">
            <img src="./Assets/theprint.png" alt="theprint-savingz" />
          </div>
          <div className="featured_on_img">
            <img src="./Assets/ani.png" alt="ani-savingz" />
          </div>
          <div className="featured_on_img">
            <img src="./Assets/asiannews.png" alt="asiannews-savingz" />
          </div>
          <div className="featured_on_img">
            <img src="./Assets/financialpost.png" alt="financialpost-savingz" />
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default FeaturedOnSection;
