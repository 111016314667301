import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useMedia } from "react-use";

const FAQ = ({ faq }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMedia("(max-width: 475px)");

  return (
    <div className={`faq-list-item ${isExpanded && "faq-list-item-height"}`}>
      <div
        onClick={() => setIsExpanded((prev) => !prev)}
        className="faq-question-wrapper"
      >
        <h3>{faq.question}</h3>
        <FaPlus
          size={isMobile ? 20 : 25}
          className={isExpanded ? "cross_icon" : "plus_icon"}
        />
      </div>
      <p className="faq-list-item-answer">{faq.answer}</p>
    </div>
  );
};

export default FAQ;
