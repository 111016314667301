import React, { useEffect } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import "./masterclass-register-successful.css";

const MasterclassRegisterSuccess = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate("/");
    }
  }, [state, navigate]);

  const goBack = () => {
    navigate("/");
  };

  return (
    <div
      style={{ width: "80%", margin: "0 auto", gap: 0 }}
      className="container"
    >
      <button
        style={{ marginTop: "1rem" }}
        onClick={goBack}
        type="button"
        className="go_back go_back_btn"
      >
        <FaArrowLeftLong />
        <p>Go Back</p>
      </button>

      <h3 className="register-successful-heading">{state.heading} ✅</h3>
      <h5 className="register-successful-text">{state.text}</h5>
      <img
        style={{ width: "70%", height: "100%", objectFit: "cover" }}
        src="./Assets/Ticket PNG shadow.png"
        alt=""
      />
    </div>
  );
};

export default MasterclassRegisterSuccess;
