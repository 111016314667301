import React from "react";
import "./stats.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import satisfied from './user-feedback-heart.png'
import tax from './Bill-4--Streamline-Flex.png'
import cost from './Non-Commercial-Rupee--Streamline-Flex.png'
import trusting from './Business-Handshake--Streamline-Flex.png'


const statsData = [
  { id: 1, value: "2,500+", label: "Satisfied Customers", icon: satisfied },
  { id: 2, value: "₹11CR+", label: "Tax Saved", icon: tax },
  { id: 3, value: "ZERO", label: "Acquisition Cost", icon: cost },
  { id: 4, value: "3,000+", label: "Trusting Partners", icon: trusting }
];

const Stats = () => {
  return (
    <div className="stats_main">
      <div className="stats">
        <Container>
          <Row className="stats_row">
            {statsData.map((stat) => (
              <Col key={stat.id} xs={12} md={3}>
                <div className="stats_inner_main_div">
                  <img src={stat.icon} alt="icon" className="stats_icon" />
                  <div className="stats_text">
                    <h1 className="stats_h1">{stat.value}</h1>
                    <p className="stats_p">{stat.label}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Stats;
