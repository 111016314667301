import React, { useState } from 'react';
import PhoneDialog from './PhoneDialog';
import './taxcal.css'; // Assuming you will style it with CSS
import sendWhatsAppMessage from './sendWhatsAppMessage'; // Import the updated function

const TaxCalculator = () => {
  const [income, setIncome] = useState(1000000);
  const [taxableIncome, setTaxableIncome] = useState(0);
  const [totalDeductions, setTotalDeductions] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [isCalculateClicked, setIsCalculateClicked] = useState(false); // New state for button activation
  const [showDialog, setShowDialog] = useState(false); // State to control the dialog visibility

  const calculateTaxDetails = (income) => {
    const totalDeductions = Math.round(475000 + (income * 0.15));
    const taxableIncome = Math.round(income - totalDeductions);
    let totalTax = 0;

    if (taxableIncome <= 250000) {
      totalTax = 0;
    } else if (taxableIncome <= 500000) {
      totalTax = Math.round(0.05 * (taxableIncome - 250000));
    } else if (taxableIncome <= 1000000) {
      totalTax = Math.round(12500 + 0.20 * (taxableIncome - 500000));
    } else {
      totalTax = Math.round(112500 + 0.30 * (taxableIncome - 1000000));
    }

    setTotalDeductions(totalDeductions);
    setTaxableIncome(taxableIncome);
    setTotalTax(totalTax);
    setIsCalculateClicked(true); // Enable the "Optimize Tax Now" button
  };

  const handleIncomeChange = (e) => {
    const value = e.target.value;
    setIncome(value);
  };

  const handleIncomeBlur = () => {
    if (income < 1000000) {
      setIncome(1000000);
    }
  };

  const handleCalculateClick = () => {
    calculateTaxDetails(parseFloat(income));
  };

  const handleOptimizeClick = () => {
    setShowDialog(true);
  };

  return (
    <>
      <div className="why_choose_row" id='taxcal'>
        <h2 className="why_choose_heading">Let’s Calculate your Income Tax Savings</h2>
        <p className="why_choose_para">
          This tool will help you estimate calculation to maximize your Income Tax
          <br /> savings within legal boundaries effectively
        </p>
      </div>
      <div className="taxcal">
        <div className="taxcal_main">
          <div className="taxcal_left">
            <div className="income-section">
              <h2>Yearly Income Amount</h2>
              <p>Enter your total yearly income to calculate how can tax you can save legally.</p>
              <input
                type="number"
                value={income}
                onChange={handleIncomeChange}
                onBlur={handleIncomeBlur}
                className="income-input"
                min="1000000"
              />
              <input
                type="range"
                min="1000000"
                max="10000000"
                value={income}
                onChange={handleIncomeChange}
                className="income-slider"
                style={{ '--slider-value': `${((income - 1000000) / 9000000) * 100}%` }}
              />
              <div className="slider-labels">
                <span>₹ 10L</span>
                <span>₹ 1Cr</span>
              </div>
              <div className='income-details'>
                <p>Woohooo! All set, hit the <span>Calculate Tax Saving</span> button below to Calculate your annual Income Tax using Savingz Club Offer</p>
              </div>
              <button className="calculate-button" onClick={handleCalculateClick}>Calculate Tax Savings</button>
              <div className='after_button'>
                <p>*This calculation is only applicable for Savingz Club Members</p>
              </div>
            </div>
          </div>
          <div className="taxcal_right">
            <div className="tax-section">
              <h2>Total Tax Payable with Savingz</h2>
              <div className="tax-amount">₹ {totalTax.toLocaleString()}*</div>
              <div className="tax-details">
                <div className="detail-item bottom_line">
                  <span>Taxable Income</span>
                  <span className='detail-value'>₹ {taxableIncome.toLocaleString()}</span>
                </div>
                <div className="detail-item">
                  <span>Total Deductions</span>
                  <span className='detail-value'>₹ {totalDeductions.toLocaleString()}</span>
                </div>
              </div>
              <button
                className={`optimize-button ${isCalculateClicked ? '' : 'disabled'}`}
                disabled={!isCalculateClicked}
                onClick={handleOptimizeClick}
              >
                Send Tax Breakdown
              </button>
              <div className='after_button'>
                <p>*Above is Estimated Annual Tax Liability</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDialog && (
        <PhoneDialog
          onClose={() => setShowDialog(false)}
          onSend={(phoneNumber) => {
            sendWhatsAppMessage(phoneNumber, taxableIncome, totalDeductions, totalTax);
            setShowDialog(false);
          }}
        />
      )}
    </>
  );
};

export default TaxCalculator;
