import React from "react";
import ModalOverlay from "./Modal";
import Verification from "../Verification";

const VerificationModal = ({ values, onClose, showModal, setIsVerified }) => {
  return (
    <ModalOverlay onClose={onClose}>
      <Verification
        values={values}
        onClose={onClose}
        onShow={showModal}
        setIsVerified={setIsVerified}
      />
    </ModalOverlay>
  );
};

export default VerificationModal;
