// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_body h4{
    display: flex;
    align-items: center;
    justify-content: center;
}

.model_btn{
    margin-right: 10px;
    padding: 10px 20px;
    border: 0px solid #E837FF !important;
    color: #7511D3;
    letter-spacing: 1.25px;
    background: transparent;
    font-size: 10px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;  
    font-weight: semibold;
    transition: background-color 0.3s ease;

}

.qr-img {
    transition: all 0.3s ease;
  }

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }

  .model_btn:hover, .qr-img:hover {
    animation: shake 0.5s;
  }

  
`, "",{"version":3,"sources":["webpack://./src/Model/model.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,oCAAoC;IACpC,cAAc;IACd,sBAAsB;IACtB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,6CAA6C;IAC7C,qBAAqB;IACrB,sCAAsC;;AAE1C;;AAEA;IACI,yBAAyB;EAC3B;;AAEF;IACI,KAAK,wBAAwB,EAAE;IAC/B,MAAM,2BAA2B,EAAE;IACnC,MAAM,0BAA0B,EAAE;IAClC,MAAM,2BAA2B,EAAE;IACnC,OAAO,wBAAwB,EAAE;EACnC;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".modal_body h4{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.model_btn{\n    margin-right: 10px;\n    padding: 10px 20px;\n    border: 0px solid #E837FF !important;\n    color: #7511D3;\n    letter-spacing: 1.25px;\n    background: transparent;\n    font-size: 10px;\n    font-weight: 600;\n    font-family: 'Poppins', sans-serif !important;  \n    font-weight: semibold;\n    transition: background-color 0.3s ease;\n\n}\n\n.qr-img {\n    transition: all 0.3s ease;\n  }\n\n@keyframes shake {\n    0% { transform: translateX(0); }\n    25% { transform: translateX(-5px); }\n    50% { transform: translateX(5px); }\n    75% { transform: translateX(-5px); }\n    100% { transform: translateX(0); }\n  }\n\n  .model_btn:hover, .qr-img:hover {\n    animation: shake 0.5s;\n  }\n\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
