import React from "react";
import "./pricing.css";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const pricingContent = [
  {
    pricingTitle: "Smart taxpayer",
    price: "Only INR 4500/-",
    cost: 4500,
    checkContent: [
      "Declaration Discussion & Tax Planning",
      "Proof Submission & Tax Saving Products",
      "Includes Salary Income Only",
      "Free LIVE ITR Filing with Tax Advisor",
    ],
  },
  {
    pricingTitle: "Smart Taxpayer Plus",
    price: "Only INR 5700/-",
    cost: 5700,
    checkContent: [
      'Everything in "Smart Taxpayer" Plan',
      "Unlimited Video Sessions with Tax Advisor",
      "Includes Capital Gains, RSU, Salary Income ",
      "Free LIVE ITR Filing with Tax Advisor",
      "Maximum Tax Saving",
      "TDS Compliance for Exemptions",
    ],
  },
  {
    pricingTitle: "Elite Pro TaxSaver",
    price: "Only INR 10500/-",
    cost: 10500,
    checkContent: [
      'Everything in "Smart Taxpayer Plus" Plan',
      "Includes Foreign Income & DTTA Filing",
      "Flexi Benefits Plan & Form 12BA Support",
      "Free LIVE ITR Filing with Tax Advisor",
      "Maximum Tax Saving",
      "100% Tax Compliance",
    ],
  },
];

const Pricing = () => {
  const navigate = useNavigate();

  const openPopup = () => {
    window.Calendly.showPopupWidget(
      "https://calendly.com/savingz/free-ca-call-clone"
    );
  };

  const redirect = (price) => {
    navigate(`?premiumPlan=${price}`);
  };

  return (
    <div id="pricing" className="pricing">
      <h2 className="why_choose_heading">Pricing Plans</h2>
      <div className="pricing-container">
        {pricingContent.map((item) => (
          <div className="col-lg-3 mb-5 mb-lg-0 pricing-card-container">
            <div className="bg-white p-5 rounded-lg shadow pricing-card">
              <div>
                <h1
                  style={{ fontWeight: "bolder" }}
                  className="pricing_title h5 text-uppercase font-weight-bold mb-4"
                >
                  {item.pricingTitle}
                </h1>
                <h2 className="h3 font-weight-bold">{item.price}</h2>

                <div className="custom-separator my-4 mx-auto bg-primary"></div>

                <ul className="list-unstyled my-5 text-small text-left">
                  {item.checkContent.map((i) => (
                    <li className="mb-3">
                      <FaCheck
                        className="mr-2"
                        color="#a61bc2"
                        style={{ marginRight: "5px" }}
                      />{" "}
                      {i}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="text-center">
                <button
                  onClick={redirect.bind(null, item.cost)}
                  className="calculate-button"
                >
                  File Now
                </button>
                <p className="pricing-still-in-doubt" onClick={openPopup}>
                  Still in doubt?
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
