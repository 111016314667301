import InputBox from "./InputBox";

const Form1 = ({
  forwardIndex,
  values,
  valuesDispatch,
  errors,
  errorsDispatch,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!Number(values.rent)) {
      errorsDispatch({
        type: "RENT",
        value: "Please provide a valid monthly rent.",
      });
      return;
    }

    if (!values.address) {
      errorsDispatch({
        type: "ADDRESS",
        value: "Please provide a valid house address.",
      });
      return;
    }

    forwardIndex();
  };

  return (
    <form className="form1 form" onSubmit={handleSubmit}>
      <InputBox
        label="What is your monthly rent?"
        id="rent"
        inputValue={values.rent}
        setInputValue={(value) => valuesDispatch({ type: "RENT", value })}
        inputError={errors.rent}
        setInputError={(value) => errorsDispatch({ type: "RENT", value })}
        type="text"
      />

      <InputBox
        label="What is the house address?"
        id="address"
        inputValue={values.address}
        setInputValue={(value) => valuesDispatch({ type: "ADDRESS", value })}
        inputError={errors.address}
        setInputError={(value) => errorsDispatch({ type: "ADDRESS", value })}
        type="text"
      />

      <button className="submitButton" type="submit">
        Proceed
      </button>
    </form>
  );
};

export default Form1;
