import React from "react";
import { Link } from "react-router-dom";
import "./whychooseus.css";
import wealth from "./Wealth Management.png";
import alternative from "./alternative-investment.png";
import arrow from "./arrow-up-right.png";
import msme from "./MSME.png";
import real from "./real-estate.png";
import tax from "./tax-saving.png";
import tax_m from "./tax_m.png";

const WhyChooseUs = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smooth scrolling
    });
  };
  return (
    <>
      <div className="why_choose_row" id="whychooseus">
        <h2 className="why_choose_heading">Why Choose Savingz?</h2>
        <p className="why_choose_para">
          Move forward from traditional CAs and wealth managers and Experience{" "}
          <br></br> what you can do with your hard earned money.
        </p>
      </div>
      <div className="why_choose_main">
        <div className="why_choose">
          <div className="five_box_main">
            <div className="five_box_top">
              <div className="five_box_top_left">
                <Link to="/itr-filing" onClick={scrollToTop}>
                  <div className="five_box_top_img_div">
                    <img className="five_box_top_img" src={tax} alt="wealth" />
                    <img
                      className="five_box_top_img_hover"
                      src={tax_m}
                      alt="tax_m"
                    />
                    <img
                      className="five_box_top_img_arrow"
                      src={arrow}
                      alt="arrow"
                    />
                  </div>
                </Link>
                <h2 className="five_box_top_h2">Tax Saving and Filing</h2>
                <p className="five_box_top_p">
                  Hassle-free annual tax planning and seamless filing. Trusted
                  by 500+ clients. Over ₹5 cr saved last season.
                </p>
              </div>

              <div className="five_box_top_right">
                <Link to="/wealth-management" onClick={scrollToTop}>
                  <div className="five_box_top_img_div">
                    <img className="five_box_top_img" src={wealth} alt="wealth" />
                    <img
                      className="five_box_top_img_hover"
                      src={wealth}
                      alt="tax_m"
                    />
                    <img
                      className="five_box_top_img_arrow"
                      src={arrow}
                      alt="arrow"
                    />
                  </div>
                </Link>
                <h2 className="five_box_top_h2">Wealth Management</h2>
                <p className="five_box_top_p">
                  Complete portfolio review with expert advice on funds, equity,
                  estate, and more. Unmatched 360° investment guidance.
                </p>
              </div>
            </div>
          </div>

          <div className="five_box_main">
            <div className="five_box_bottom">
              <div className="five_box_bottom_left">
                <Link to="/real-estate" onClick={scrollToTop}>
                  <div className="five_box_top_img_div">
                    <img className="five_box_top_img" src={real} alt="wealth" />
                    <img
                      className="five_box_top_img_hover"
                      src={real}
                      alt="tax_m"
                    />
                    <img
                      className="five_box_top_img_arrow"
                      src={arrow}
                      alt="arrow"
                    />
                  </div>
                </Link>
                <h2 className="five_box_top_h2">Real Estate</h2>
                <p className="five_box_top_p">
                  Personalized real estate investments with portfolio guidance
                  and loan assistance
                </p>
              </div>
              <div className="five_box_bottom_mid">
                <Link to="/alternative-investment" onClick={scrollToTop}>
                  <div className="five_box_top_img_div">
                    <img
                      className="five_box_top_img"
                      src={alternative}
                      alt="wealth"
                    />
                    <img
                      className="five_box_top_img_hover"
                      src={alternative}
                      alt="tax_m"
                    />
                    
                    <img
                      className="five_box_top_img_arrow"
                      src={arrow}
                      alt="arrow"
                    />
                  </div>
                </Link>
                <h2 className="five_box_top_h2">Alternate Investment</h2>
                <p className="five_box_top_p">
                  Explore top diversification options for smart, alternative
                  wealth growth.
                </p>
              </div>

              <div className="five_box_bottom_right">
                <Link to="/msme-investment" onClick={scrollToTop}>
                  <div className="five_box_top_img_div">
                    <img className="five_box_top_img" src={msme} alt="wealth" />
                    <img
                      className="five_box_top_img_hover"
                      src={msme}
                      alt="tax_m"
                    />
                    <img
                      className="five_box_top_img_arrow"
                      src={arrow}
                      alt="arrow"
                    />
                  </div>
                </Link>
                <h2 className="five_box_top_h2">For MSMEs</h2>
                <p className="five_box_top_p">
                  Full accounting and bookkeeping to maximize ITC and optimize
                  finances.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUs;
