import React from "react";
import "./tnc.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RippleButton from "../RippleButton/RippleButton";

const ItrFiling = () => {
  const openPopup = () => {
    window.Calendly.showPopupWidget(
      "https://calendly.com/savingz/free-ca-call-clone"
    );
  };
  return (
    <>
      <div className="tnc_main">
        <div className="tnc">
          <h2>Itr Filing</h2>

          <Row>
            <Col className="tnc_mainsec">
              <div className="tnc_leftsec">
                <h4>Savings made simple</h4>
                <p>
                  Don’t know where to start from? It's simple, click on that
                  button and wait for our call, let's take it step by step. We
                  are here to help people like you get rich and achieve their
                  goals.
                </p>
                <RippleButton onClick={openPopup} className="tnc_btn">
                  CONTACT US<i className="fi fi-rr-arrow-right arrow"></i>
                </RippleButton>
              </div>
              <div className="tnc_rightsec">
                <img src="./Assets/gandhiji.png" alt="" />
              </div>
            </Col>
            <Col className="tnc_secondsec"></Col>
          </Row>
          <div className="terms-and-conditions">
            <p>
              Savingz offers tax-saving and filing services that aim to make tax
              planning and filing effortless for clients. We emphasize a smooth
              tax filing experience and comprehensive tax optimization, likely
              utilizing various strategies to minimize clients' tax liabilities
              within the bounds of the law. With over 500 clients served, we
              claim to have saved more than ₹5 crores for their clients
              collectively.
            </p>

            <p>
              <strong>Our services likely involve:</strong>
            </p>
            <ul>
              <li>
                <p>
                  <strong>Effortless Planning:</strong> Simplifying the process
                  of tax planning by providing guidance and tools to help
                  clients optimize their tax strategies.
                </p>
              </li>
              <li>
                <p>
                  <strong>Smooth Tax Filing Experience:</strong> Streamlining
                  the tax filing process, possibly through user-friendly
                  interfaces, automated data entry, and expert support.
                </p>
              </li>
              <li>
                <p>
                  <strong>Comprehensive Tax Optimization:</strong> Offering a
                  range of tax optimization strategies tailored to clients'
                  financial situations to maximize tax savings.
                </p>
              </li>
              <li>
                <p>
                  <strong>Client Savings:</strong> Demonstrating a track record
                  of success in saving taxes for their clients, which could be
                  through various means such as deductions, credits,
                  investments, and strategic planning.
                </p>
              </li>
            </ul>

            <p>
              Overall, Savingz aims to provide a comprehensive suite of services
              to help clients effectively manage their taxes, potentially
              offering a combination of technology-driven solutions and expert
              advice to deliver value to their clients.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItrFiling;
