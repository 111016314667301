import React from "react";
import "./tnc.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RippleButton from "../RippleButton/RippleButton";

const RealEstate = () => {
  const openPopup = () => {
    window.Calendly.showPopupWidget(
      "https://calendly.com/savingz/free-ca-call-clone"
    );
  };
  return (
    <>
      <div className="tnc_main">
        <div className="tnc">
          <h2>Real Estate</h2>

          <Row>
            <Col className="tnc_mainsec">
              <div className="tnc_leftsec">
                <h4>Savings made simple</h4>
                <p>
                  Don’t know where to start from? It's simple, click on that
                  button and wait for our call, let's take it step by step. We
                  are here to help people like you get rich and achieve their
                  goals.
                </p>
                <RippleButton onClick={openPopup} className="tnc_btn">
                  CONTACT US<i className="fi fi-rr-arrow-right arrow"></i>
                </RippleButton>
              </div>
              <div className="tnc_rightsec">
                <img src="./Assets/gandhiji.png" alt="" />
              </div>
            </Col>
            <Col className="tnc_secondsec"></Col>
          </Row>
          <div className="terms-and-conditions">
            <p>
              At Savingz, we introduce you to a world of new-age investment
              opportunities beyond traditional options. Explore a diverse range
              of asset classes through our Alternate Investments feature.
            </p>

            <p>
              Own a stake in high-end properties with Fractional Ownership by
              Spacez. Diversify your portfolio and enjoy profits generated from
              luxury homes and villas in prime metro locations. Benefit from
              shared ownership without the hefty upfront costs.
            </p>

            <p>
              On average, we leverage our strengths to offer our micro investors
              returns as big as 30% on average. With Savingz, you can trust that
              your wealth is in capable hands. Our commitment to personalized
              advice and strategic planning ensures that your financial future
              is secure and prosperous.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RealEstate;
