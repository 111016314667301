// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #faf9fa !important;
  overflow-x: hidden;
}

@media screen and (min-width: 1024px) {
  .calendly-popup {
    margin: 0 auto;
    height: 180% !important;
    overflow: hidden !important;
    background-color: white;
  }

  .calendly-popup-content iframe {
    overflow: hidden !important;
    margin: 0 auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/main.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,kBAAkB;AACpB;;AAEA;EACE;IACE,cAAc;IACd,uBAAuB;IACvB,2BAA2B;IAC3B,uBAAuB;EACzB;;EAEA;IACE,2BAA2B;IAC3B,cAAc;EAChB;AACF","sourcesContent":["body {\n  background-color: #faf9fa !important;\n  overflow-x: hidden;\n}\n\n@media screen and (min-width: 1024px) {\n  .calendly-popup {\n    margin: 0 auto;\n    height: 180% !important;\n    overflow: hidden !important;\n    background-color: white;\n  }\n\n  .calendly-popup-content iframe {\n    overflow: hidden !important;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
