import React from "react";
import MoneyItem from "./MoneyItem";

const PotentialSection = ({ taxMasterclass }) => {
  const moneyGrid = taxMasterclass
    ? [
        {
          img: "./Assets/masterclass-question.png",
          heading: "I pay too much Taxes!",
          para: "I’m not able to save enough",
        },
        {
          img: "./Assets/masterclass-question.png",
          heading: "Taxes are Complex!",
          para: "I’m not able to understand why do I have to pay so much taxes",
        },
        {
          img: "./Assets/masterclass-question.png",
          heading: "Jargons don’t Make sense to me!",
          para: "What is deductions? What is exemptions?",
        },
        {
          img: "./Assets/masterclass-question.png",
          heading: "Can I trust my CA?",
          para: "He does things on his own, can I trust him?",
        },
      ]
    : [
        {
          img: "./Assets/masterclass-question.png",
          heading: "Can't afford a Financial Planner",
          para: "I only rely on friends & family. Financial planners are expensive",
        },
        {
          img: "./Assets/masterclass-question.png",
          heading: "Mutual Funds are complicated",
          para: "I'm not able to identify top performing mutual funds",
        },
        {
          img: "./Assets/masterclass-question.png",
          heading: "I pay too much Taxes",
          para: "I'm not able to save enough in taxes",
        },
        {
          img: "./Assets/masterclass-question.png",
          heading: "Where all should I invest?",
          para: "I'm not able to invest in Indian market, US market, debt, gold, and real estate",
        },
      ];

  const heading = taxMasterclass
    ? "Do you struggle with unlocking the"
    : "Do you struggle with unlocking the";
  const para = taxMasterclass
    ? "True potential of Tax Optimization"
    : "True potential of your hard earned money";

  return (
    <div className="potential-section">
      <div className="section-heading-2">
        <p class="section-heading-pr-2">{heading}</p>
        <h2 class="section-heading-text">{para}</h2>
      </div>
      <div className="money-grid">
        {moneyGrid.map((item, index) => (
          <MoneyItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default PotentialSection;
