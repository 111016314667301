import React from "react";
import "./tnc.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RippleButton from "../RippleButton/RippleButton";
const AlternateInvestment = () => {
  
  const openPopup = () => {
    window.Calendly.showPopupWidget(
      "https://calendly.com/savingz/free-ca-call-clone"
    );
  };

  return (
    <>
      <div className="tnc_main">
        <div className="tnc">
          <h2>Alternate Investment</h2>

          <Row>
            <Col className="tnc_mainsec">
              <div className="tnc_leftsec">
                <h4>Savings made simple</h4>
                <p>
                  Don’t know where to start from? It's simple, click on that
                  button and wait for our call, let's take it step by step. We
                  are here to help people like you get rich and achieve their
                  goals.
                </p>
                <RippleButton onClick={openPopup} className="tnc_btn">
                  CONTACT US<i className="fi fi-rr-arrow-right arrow"></i>
                </RippleButton>
              </div>
              <div className="tnc_rightsec">
                <img src="./Assets/gandhiji.png" alt="" />
              </div>
            </Col>
            <Col className="tnc_secondsec"></Col>
          </Row>
          <div className="terms-and-conditions">
            <p>
              At Savingz, we introduce you to a world of new-age investment
              opportunities beyond traditional options. Explore a diverse range
              of asset classes through our Alternate Investments feature.
            </p>

            <p>
              <strong>Fractional Investment:</strong>
              <br />
              Own a stake in high-end properties with Fractional Ownership by
              Spacez. Diversify your portfolio and enjoy profits generated from
              luxury homes and villas in prime metro locations. Benefit from
              shared ownership without the hefty upfront costs.
            </p>

            <p>
              <strong>P2P Lending:</strong>
              <br />
              Mitigate loan fraud risks through stringent vetting processes.
              Partnering with established industry players ensures credibility
              and reduces default risks. Proactive measures are taken to
              safeguard lenders' interests and maintain trust in our lending
              ecosystem.
            </p>

            <p>
              <strong>Invoice Discounting:</strong>
              <br />
              Simplify invoice finance with Invoice Discounting. Sell unpaid
              invoices to receive a cash advance. Access immediate funds while
              maintaining cash flow stability.
            </p>

            <p>
              Dive into the world of Alternate Investments with Savingz and
              unlock new avenues for wealth growth. Your financial future
              awaits!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlternateInvestment;
