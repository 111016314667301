import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Slider from "react-slick";
import "./FeatureSlider.css";
import finance from "./finance.png";
import business from "./Business.png";
import fraction from "./fractions.png";
import intrest from "./interest-rate.png";
import tex from "./tax.png";
import wealth from "./wealth.png";
import pattern from "./pattern.png";

const FeatureSlider = () => {
  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  return (
    <>
      <div className="FeatureSlider_main">
        <div className="FeatureSlider">
          <Row>
            <Col sm={12}>
              <h2 className="why_choose_heading">Our features</h2>
              <p className="why_choose_para">
                Because Success Deserves a Partner, Everything you need in one
                app
              </p>
            </Col>
          </Row>

          <Container fluid="md">
            <Row>
              <Col>
                <div className="FeatureSliderCrousel">
                  <div className="FeatureSlider_container">
                    <div className="FeatureSlider-container">
                      <Slider {...settings}>
                        <div className="FeatureCard">
                          <div className="FeatureSlidercard">
                            <div className="FeatureSlidercard-body">
                              <h3 style={{ fontSize: "0rem" }}>
                                1{" "}
                                <img
                                  className="feature_image"
                                  src={fraction}
                                  alt="wealth"
                                />
                              </h3>

                              <h2 className="FeatureSlidercard-title">
                                Invest in Fractions
                              </h2>
                              <p className="FeatureSlidercard-text">
                                Get industry leading returns without emptying
                                your emergency fund
                              </p>
                            </div>
                            <Col
                              sm={6}
                              className="d-flex justify-content-center mid_image_bg"
                            >
                              <div
                                className="features_img"
                                style={{
                                  backgroundImage: `url(${pattern})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              >
                                <img
                                  src="./Assets/1.png"
                                  alt="Feature Description"
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                        <div className="FeatureCard">
                          <div className="FeatureSlidercard">
                            <div className="FeatureSlidercard-body">
                              <h3 style={{ fontSize: "0rem" }}>
                                1{" "}
                                <img
                                  className="feature_image"
                                  src={intrest}
                                  alt="wealth"
                                />
                              </h3>
                              <h2 className="FeatureSlidercard-title">
                                Earn interest
                              </h2>
                              <p className="FeatureSlidercard-text">
                                Even on your referrals so you make money
                                together, as it is supposed to be
                              </p>
                            </div>
                            <Col
                              sm={6}
                              className="d-flex justify-content-center mid_image_bg"
                            >
                              <div
                                className="features_img"
                                style={{
                                  backgroundImage: `url(${pattern})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              >
                                <img
                                  src="./Assets/4.png"
                                  alt="Feature Description"
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                        <div className="FeatureCard">
                          <div className="FeatureSlidercard">
                            <div className="FeatureSlidercard-body">
                              <h3 style={{ fontSize: "0rem" }}>
                                1{" "}
                                <img
                                  className="feature_image"
                                  src={tex}
                                  alt="wealth"
                                />
                              </h3>
                              <h2 className="FeatureSlidercard-title">
                                Taxes, US and AI
                              </h2>
                              <p className="FeatureSlidercard-text">
                                Optimize your tax returns with AI and India’s
                                best financial advisors
                              </p>
                            </div>
                            <Col
                              sm={6}
                              className="d-flex justify-content-center mid_image_bg"
                            >
                              <div
                                className="features_img"
                                style={{
                                  backgroundImage: `url(${pattern})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              >
                                <img
                                  src="./Assets/2.png"
                                  alt="Feature Description"
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                        <div className="FeatureCard">
                          <div className="FeatureSlidercard">
                            <div className="FeatureSlidercard-body">
                              <h3 style={{ fontSize: "0rem" }}>
                                1{" "}
                                <img
                                  className="feature_image"
                                  src={business}
                                  alt="wealth"
                                />
                              </h3>
                              <h2 className="FeatureSlidercard-title">
                                Invest in business
                              </h2>
                              <p className="FeatureSlidercard-text">
                                Choose from the exclusive drop every week and
                                get a chance to invest
                              </p>
                            </div>
                            <Col
                              sm={6}
                              className="d-flex justify-content-center mid_image_bg"
                            >
                              <div
                                className="features_img"
                                style={{
                                  backgroundImage: `url(${pattern})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              >
                                <img
                                  src="./Assets/3.png"
                                  alt="Feature Description"
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                        <div className="FeatureCard">
                          <div className="FeatureSlidercard">
                            <div className="FeatureSlidercard-body">
                              <h3 style={{ fontSize: "0rem" }}>
                                1{" "}
                                <img
                                  className="feature_image"
                                  src={finance}
                                  alt="wealth"
                                />
                              </h3>
                              <h2 className="FeatureSlidercard-title">
                                Finance Delivered!
                              </h2>
                              <p className="FeatureSlidercard-text">
                                Get handpicked juiciest finance news everyday
                                with your morning coffee
                              </p>
                            </div>
                            <Col
                              sm={6}
                              className="d-flex justify-content-center mid_image_bg"
                            >
                              <div
                                className="features_img"
                                style={{
                                  backgroundImage: `url(${pattern})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              >
                                <img
                                  src="./Assets/5.png"
                                  alt="Feature Description"
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                        <div className="FeatureCard">
                          <div className="FeatureSlidercard">
                            <div className="FeatureSlidercard-body">
                              <h3 style={{ fontSize: "0rem" }}>
                                1{" "}
                                <img
                                  className="feature_image"
                                  src={wealth}
                                  alt="wealth"
                                />
                              </h3>
                              <h2 className="FeatureSlidercard-title">
                                Wealth Simplified!
                              </h2>
                              <p className="FeatureSlidercard-text">
                                Exclusive access to save tax, do investment and
                                much more
                              </p>
                            </div>
                            <Col
                              sm={6}
                              className="d-flex justify-content-center mid_image_bg"
                            >
                              <div
                                className="features_img"
                                style={{
                                  backgroundImage: `url(${pattern})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              >
                                <img
                                  src="./Assets/6.png"
                                  alt="Feature Description"
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default FeatureSlider;
