import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Companeycrousel.css";
import delhivery from './delhivery.png'
import dream from './dream11.png'
import flipcart from './flipcart.png'
import google from './google.png'
import hdfc from './hdfc.png'
import meesho from './meesho.png'
import nvedia from './nvedia.png'
import nykaa from './nykaa.png'
import razorpay from './razorpay.png'

const CompaneysCrousel = () => {
  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="CompaneysCrousel_main">
      <div className="CompaneysCrousel">
        <div className="slider_heading_main_trust">
        <div className="slider_heading_trust">Trusted by Over 150 of the World’s Top Companies</div>
        </div>
        
        {/* <Container fluid="md">
          <Row>
            <Col> */}
              <Slider className="slider_main_parent" {...settings}>
                {/* Images must be repeated as <div> elements */}
                <div className="slider_img"><img src={delhivery} alt="" /></div>
                <div className="slider_img"><img src={dream} alt="" /></div>
                <div className="slider_img"><img src={flipcart} alt="" /></div>
                <div className="slider_img"><img src={google} alt="" /></div>
                <div className="slider_img"><img src={hdfc} alt="" /></div>
                <div className="slider_img"><img src={meesho} alt="" /></div>
                <div className="slider_img"><img src={nvedia} alt="" /></div>
                <div className="slider_img"><img src={nykaa} alt="" /></div>
                <div className="slider_img"><img src={razorpay} alt="" /></div>
              </Slider>
              <div className="slider_down_div"></div>
            {/* </Col>
          </Row>
        </Container> */}
      </div>
    </div>
  );
};

export default CompaneysCrousel;
