import React, { useEffect, useReducer, useState } from "react";
import InputBox from "../../../RentReceipt/InputBox";
import { displayRazorpay, handleFreeClient, validateInputs } from "../actions";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useSearchParams } from "react-router-dom";

const initials = {
  name: "",
  email: "",
  number: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "NAME":
      return { ...state, name: action.value };
    case "NUMBER":
      return { ...state, number: action.value };
    case "EMAIL":
      return { ...state, email: action.value };
    default:
      return state;
  }
};

const COUPON_CODE = "SAVINGZ20";

const MasterclassPayment = ({ heading, goBackUrl, payment }) => {
  const [values, valuesDispatch] = useReducer(reducer, initials);
  const [errors, errorsDispatch] = useReducer(reducer, initials);
  const [pricingAmount, setPricingAmount] = useState(payment);
  const [couponCodeError, setCouponCodeError] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const goBack = () => {
    navigate(goBackUrl);
  };

  const handleCouponCode = () => {
    if (couponCode === COUPON_CODE) {
      setPricingAmount(payment * 0.8);
    } else {
      setCouponCodeError("Invalid Coupon Code!");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validateInputs(values, errorsDispatch);
    if (!isValid) return;
    if (searchParams.get("premiumPlan")) {
      await displayRazorpay({
        navigate,
        name: values.name,
        contact: values.number,
        email: values.email,
        campaignId: `Tax Filing=${pricingAmount}`,
        pricingAmount: pricingAmount,
        successHeading: `Thank you for completing your ITR filing payment. Our team will contact you within 3 working hours.`,
        successText:
          "For urgent matters, please WhatsApp or call Ketaki at 8329019904.",
      });
    } else {
      await handleFreeClient({
        campaignId: `campaign=${searchParams.get("campaignId")}`,
        navigate,
        name: values.name,
        number: values.number,
        email: values.email,
        successHeading: "You registered for the webinar successfully!",
        successText: "We'll reach out to your after some time.",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="payment_form_container" className="container">
      <button onClick={goBack} type="button" className="go_back">
        <FaArrowLeftLong />
        <p>Go Back</p>
      </button>
      <div className="masterclass_payment_container">
        {/* <h2 className="payment_heading">Payment Details</h2> */}
        <h2 className="payment_heading">{heading}</h2>
        <form className="form1 form" onSubmit={handleSubmit}>
          <InputBox
            label="Name"
            id="name"
            inputValue={values.name}
            setInputValue={(value) => valuesDispatch({ type: "NAME", value })}
            inputError={errors.name}
            setInputError={(value) => errorsDispatch({ type: "NAME", value })}
            type="text"
          />

          <InputBox
            label="Email"
            id="email"
            inputValue={values.email}
            setInputValue={(value) => valuesDispatch({ type: "EMAIL", value })}
            inputError={errors.email}
            setInputError={(value) => errorsDispatch({ type: "EMAIL", value })}
            type="email"
          />

          <InputBox
            label="Phone"
            id="phone"
            inputValue={values.number}
            setInputValue={(value) => valuesDispatch({ type: "NUMBER", value })}
            inputError={errors.number}
            setInputError={(value) => errorsDispatch({ type: "NUMBER", value })}
            type="text"
          />

          {searchParams.get("premiumPlan") && (
            <div style={{ marginLeft: "auto" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  onChange={(e) => {
                    setCouponCodeError("");
                    setCouponCode(e.target.value);
                  }}
                  style={{ padding: ".3rem", borderRadius: 0, width: "70%" }}
                  type="text"
                  placeholder="Enter a coupon code"
                />
                <button
                  type="button"
                  className="nav_btn_two"
                  style={{
                    fontSize: "inherit",
                    margin: 0,
                    padding: ".4rem",
                    gap: 0,
                    borderRadius: 0,
                    width: "30%",
                  }}
                  onClick={handleCouponCode}
                >
                  APPLY
                </button>
              </div>
              {couponCodeError && (
                <p className="inputError">{couponCodeError}</p>
              )}
            </div>
          )}

          <button
            style={{ width: "100%" }}
            className="submitButton"
            type="submit"
          >
            {pricingAmount ? `Pay ₹${pricingAmount}` : "Pay"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default MasterclassPayment;
