import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import './phoneNumberDialog.css'; // Ensure this file contains styles for the dialog
import link from './link_black.png'; // Ensure this path is correct

function PhoneNumberDialog(props) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const body = document.querySelector('body');
    if (props.show) {
      body.classList.add('blur-background');
    } else {
      body.classList.remove('blur-background');
    }
    return () => {
      body.classList.remove('blur-background');
    };
  }, [props.show]);

  const handleSend = () => {
    // Validate phone number format
    if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) {
      setError('Please enter a valid 10-digit phone number');
      return;
    }
    setError('');
    const formattedPhoneNumber = `91${phoneNumber}`;
    props.onSend(formattedPhoneNumber);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="custom-modal-body">
        <Form className="phone-number-form">
          <Form.Group controlId="formPhoneNumber" className="phone-number-group">
            <Form.Control
              type="tel"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="phone-number-input"
            />
            <div className="input-group-append">
              <button type="button" onClick={handleSend} className="send-button">
                <img src={link} alt="send icon" className="send-icon" />
              </button>
            </div>
          </Form.Group>
          {error && <Form.Text className="error-message">{error}</Form.Text>}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default PhoneNumberDialog;
