import React, { useEffect, useRef, useState } from "react";
import { sendOtp, verifyOtp } from "./actions";
const numberOfDigits = 4;

const Verification = ({ values, onClose, onShow, setIsVerified }) => {
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [otpError, setOtpError] = useState(null);
  const otpBoxReference = useRef([]);
  const [countdown, setCountdown] = useState(30);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function customFormatNumber(number) {
    const str = number.toString();
    const formattedNumber = str.replace(/(\d{3})(\d{2})(\d{5})/, "$1 $2 $3");
    return formattedNumber;
  }

  const resendOtp = async () => {
    if (countdown > 0) return;
    await sendOtp(values.number, onShow);
    setCountdown(30);
  };

  const handleVerification = async () => {
    let err = false;
    otp.forEach((i) => {
      if (!Number(i) && i !== "0") {
        err = true;
      }
    });
    if (err) {
      setOtpError("Please enter a valid OTP!");
      return;
    } else setOtpError(null);
    let str = "";
    otp.forEach((i) => (str += i));
    await verifyOtp(str, onClose, setOtpError, setIsVerified);
  };

  useEffect(() => {
    if (countdown === 0) return;
    const interval = setInterval(() => {
      setCountdown((prev) => (prev -= 1));
    }, 1000);
    return () => clearInterval(interval);
  }, [countdown]);

  return (
    <div className="verification_container">
      <div>
        <h2>Verify your phone number</h2>
        <div>
          <p className="otp_sent_para">
            Otp sent to +91 <span>{customFormatNumber(values.number)}</span>
          </p>
        </div>
      </div>
      <div>
        <div className="verification_metadata">
          <p>Enter 4-digit OTP</p>
          <div>
            {countdown !== 0 && <p>Resend in {countdown}s</p>}
            {countdown === 0 && (
              <p className="resend_text" onClick={resendOtp}>
                Resend OTP
              </p>
            )}
          </div>
        </div>
        <div className="verification">
          {otp.map((digit, index) => (
            <div key={index} className={`otp ${otpError && "otp_error"}`}>
              <input
                key={index}
                type="number"
                value={digit}
                maxLength={1}
                onChange={(e) => {
                  setOtpError(null);
                  if (
                    (!Number(e.target.value) &&
                      e.target.value !== " " &&
                      e.target.value.trim() !== "" &&
                      e.target.value !== "0") ||
                    e.target.value.length > 1
                  )
                    return;
                  handleChange(e.target.value, index);
                }}
                onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                ref={(reference) =>
                  (otpBoxReference.current[index] = reference)
                }
              />
            </div>
          ))}
        </div>
        {otpError && <p className="otpError">{otpError}</p>}
        <button
          style={{
            marginTop: "1.5rem",
            borderRadius: ".3rem",
            width: "15erm",
            fontWeight: "bold",
          }}
          className="submitButton"
          onClick={handleVerification}
        >
          Verify
        </button>
      </div>
    </div>
  );
};

export default Verification;
