import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Slider from "react-slick";
import "./testimonials.css";

const Testimonials = () => {
  const testimonials = [
    {
      img: "./Assets/asmita.jpg",
      name: "Asmita Mandal",
      position: "Senior HR, Accenture",
      feedback: "Saved additional 1L in taxes with Savingz, since last 5 yrs I used to file tax on my own. CA's on Savingz are smart and have reasonable knowledge. Excellent customer support and super friendly tax filing.",
    },
    {
      img: "./Assets/sarad.jpg",
      name: "Sriram Sarad Samoju",
      position: "Product Designer, Zoomcar",
      feedback: "Savingz transformed my tax planning. Their expertise helped me optimize my deductions and save significantly. Highly recommended!",
    },
    {
      img: "./Assets/aayush.jpg",
      name: "Aayush Chaudhary",
      position: "Senior AI Engineer, Dream11",
      feedback: "I'm grateful for Savingz's exceptional customer service. They were responsive, professional, and went above and beyond to address my tax-related queries.",
    },
    {
      img: "./Assets/surendra.jpg",
      name: "Surendra Sheshma",
      position: "SDE III, Beepkart",
      feedback: "Choosing Savingz was a game-changer for me. Their comprehensive understanding of tax laws and attention to detail saved me a significant amount of money.",
    },
    {
      img: "./Assets/piyush.jpg",
      name: "Piyush Chittara",
      position: "CEO, StreamNFT",
      feedback: "Great initiative, helped understand claimable benefits and save taxes 🙌 Loved the team and prompt reverts.",
    },
    {
      img: "./Assets/sourabrata.jpg",
      name: "Sourabrata Samanta",
      position: "SDE II, Accenture",
      feedback: "This is the first time I got this much return, thanks to Savingz team. I didn't even knew I can get this much.",
    },
    {
      img: "./Assets/reema.jpg",
      name: "Reema Singla",
      position: "Lead Analyst, Fidelity Investment",
      feedback: "Recently did my first investment through the app and I loved their personalised walkthrough at all steps. Waiting for my form 16 this year and I will also file it through you guys",
    },
    {
      img: "./Assets/pingal.jpg",
      name: "Pingal Kakati",
      position: "Lead Designer, Razorpay",
      feedback: "I'm impressed with Savingz's personalized approach. They analyzed my unique financial situation and provided tailored tax strategies that saved me a substantial amount.",
    },
    {
      img: "./Assets/wakar.jpg",
      name: "Wakar Khan",
      position: "SDE III, Swiggy",
      feedback: "Savingz exceeded my expectations. Their knowledgeable CAs provided valuable insights, helping me navigate complex tax regulations and maximize my savings",
    },
    {
      img: "./Assets/sarthak.jpg",
      name: "Sarthak Agarwal",
      position: "Software Engineer, Bloomberg",
      feedback: "I’ve been using Savingz for the past year, and it’s been a game-changer! As an NRI, navigating the complex Indian tax filing was nightmare, But these people took care of it",
    },
  ];

  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const openTypeformDialog = () => {
    const modal = document.createElement("div");
    modal.classList.add("typeform-dialog");

    const iframe = document.createElement("iframe");
    iframe.src = "https://kn06djn2bvg.typeform.com/to/CsAd32j8";
    iframe.width = "100%";
    iframe.height = "500px";
    iframe.frameBorder = "0";
    iframe.allow = "fullscreen";

    const closeButton = document.createElement("button");
    closeButton.innerText = "X";
    closeButton.classList.add("close-button");

    modal.appendChild(closeButton);
    modal.appendChild(iframe);
    document.body.insertBefore(modal, document.body.firstChild);

    const closeModal = () => {
      document.body.removeChild(modal);
    };

    // Close the modal if clicked outside the iframe or on the close button
    modal.addEventListener("click", (event) => {
      if (event.target === modal || event.target === closeButton) {
        closeModal();
      }
    });
  };

  return (
    <>
      <div className="testimonials_main">
        <div className="testimonials">
          <Row>
            <Col sm={12}>
              <h2 className="why_choose_heading">What Customer Says</h2>
            </Col>
            {/* <Col sm={4} className="text-sm-end">
              <RippleButton className="testimonals_btn" onClick={openTypeformDialog}>
                TALK TO US<i className="fi fi-rr-arrow-right arrow"></i>
              </RippleButton>
            </Col> */}
          </Row>
          <Slider className="testimonals_carousel_slider" {...settings}>
            {testimonials.map((testimonial, index) => (
              <div className="testimonals_carousel_main" key={index}>
                <div className="testimonals_carousel_main_bottom">
                  {testimonial.feedback}
                </div>
                <div className="testimonals_carousel_main_top">
                  <div className="testimonals_carousel_main_top_left">
                    <div className="testimonals_carousel_main_top_left_img">
                      <img src={testimonial.img} alt={testimonial.name} />
                    </div>
                    <div className="testimonals_carousel_main_top_left_details">
                      <h2>{testimonial.name}</h2>
                      <h3>{testimonial.position}</h3>
                    </div>
                  </div>
                </div>
                
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
