import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import "./navbar.css";
import Model from "../Model/Model";
import inrpng from "./inrpng.png";
import down from "./down.png";
import download from "./download.png";
import headset from "./headset.png";
import { useModal } from '../ModalContext';

function Navebar() {
  const [modalShow, setModalShow] = useState(false);
  const modalRef = useModal(); // Use context for the modal

  const openTypeformDialog = () => {
    if (modalRef?.current) {
      modalRef?.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    const modal = document.createElement("div");
    modal.classList.add("typeform-dialog");
    modalRef.current = modal; // Store reference to the modal

    const iframe = document.createElement("iframe");
    iframe.src = "https://kn06djn2bvg.typeform.com/to/CsAd32j8";
    iframe.width = "100%";
    iframe.height = "500px";
    iframe.frameBorder = "0";
    iframe.allow = "fullscreen";

    const closeButton = document.createElement("button");
    closeButton.innerText = "X";
    closeButton.classList.add("close-button");

    modal.appendChild(closeButton);
    modal.appendChild(iframe);
    document.body.insertBefore(modal, document.body.firstChild);

    const closeModal = () => {
      document.body.removeChild(modal);
      modalRef.current = null; // Clear reference to the modal
    };

    modal.addEventListener("click", (event) => {
      if (event.target === modal || event.target === closeButton) {
        closeModal();
      }
    });
  };

  const openPopup = () => {
    // Function to open the Calendly popup
    window.Calendly.showPopupWidget('https://calendly.com/savingz/free-ca-call-clone');
  };


  return (
    <div className="nav_main_container">
      <Navbar collapseOnSelect expand="lg" className="nav_main_nav">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img className="nav_logo_savingz" src="./Assets/savingz.png" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto nav_items">
              <Nav.Link as={HashLink} smooth to="/#whychooseus" className="nav_link_container">
                <img src={inrpng} alt="Inr png" style={{ marginRight: '2px' }} /> Why Savingz?
              </Nav.Link>
              <Nav.Link as={HashLink} smooth to="/#products" className="nav_link_container">
                Our Features 
              </Nav.Link>
              <Nav.Link as={HashLink} smooth to="/#testimonials" className="nav_link_container">
                Customer Feedback
              </Nav.Link>
              <Nav.Link as={Link} to="/masterclass" className="nav_link_container">
                Masterclass
              </Nav.Link>
              <Dropdown>
                <Dropdown.Toggle as={Nav.Link} className="nav_link_container">
                  Tools <img src={down} alt="Inr png" style={{ marginRight: '5px' }} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={HashLink} to="/#taxcal">Calculate Tax</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/rent-receipt">Generate Rent Receipt</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
            <Nav className="btn_navbar">
              <button className="nav_btn_two" onClick={openPopup}>  FREE CA CALL <img src={headset} alt="Inr png" /></button>
              <button className="nav_btn_three" onClick={() => setModalShow(true)}> Download App <img src={download} alt="Inr png" style={{ marginLeft: '5px' }} /></button>
              <Model show={modalShow} onHide={() => setModalShow(false)} />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {modalShow && <div className="blur-background"></div>}
    </div>
  );
}

export default Navebar;
