import React from "react";
import "./tnc.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RippleButton from "../RippleButton/RippleButton";

const PrivacyPolicy = () => {
  const openPopup = () => {
    window.Calendly.showPopupWidget(
      "https://calendly.com/savingz/free-ca-call-clone"
    );
  };
  return (
    <>
      <div className="tnc_main">
        <div className="tnc">
          <h2>Privacy Policy</h2>

          <Row>
            <Col className="tnc_mainsec">
              <div className="tnc_leftsec">
                <h4>Savings made simple</h4>
                <p>
                  Don’t know where to start from? It's simple, click on that
                  button and wait for our call, let's take it step by step. We
                  are here to help people like you get rich and achieve their
                  goals.
                </p>
                <RippleButton onClick={openPopup} className="tnc_btn">
                  CONTACT US<i className="fi fi-rr-arrow-right arrow"></i>
                </RippleButton>
              </div>
              <div className="tnc_rightsec">
                <img src="./Assets/gandhiji.png" alt="" />
              </div>
            </Col>
            <Col className="tnc_secondsec"></Col>
          </Row>
          <div className="terms-and-conditions">
          <p>Effective Date: 28/06/2023</p>
      <p><strong>1. Mobile App Data</strong><br/>
        Savingz App (Formerly known as Sawingz) requires three permissions to offer users an engaging experience:<br/>
        a. <strong>Notification:</strong> To send users important updates about features and announcements.<br/>
        b. <strong>Location:</strong> To identify and block spam by pinpointing highlighted locations.<br/>
        c. <strong>Contacts:</strong> Savingz accesses saved contact information to enhance the user experience with its referral feature, but does not save or transfer contact information to any third party servers.</p>

      <p><strong>2. Introduction</strong><br/>
        Savingz ("we," "us," or "our") is dedicated to safeguarding the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal data when you use our website (www.savingz.in) and any related products or services (collectively, the "Services"). By accessing or using our Services, you agree to the terms and practices described in this Privacy Policy. If you do not agree with the practices outlined herein, please do not use our Services.</p>

      <p><strong>3. Information We Collect</strong><br/>
        We may collect various types of information from you, including:<br/>
        a. <strong>Personal Information:</strong> This includes information that can be used to identify you, such as your name, email address, contact number, and other identifying details.<br/>
        b. <strong>Financial Information:</strong> If you choose to engage our tax filing services or any financial-related offerings, we may collect information related to your financial situation, income, deductions, investments, and other relevant financial data.<br/>
        c. <strong>Usage Data:</strong> Our Services may automatically collect certain information about your device, browser, and how you interact with our website. This may include your IP address, browser type, access times, and pages visited<br/>
        d. <strong>Cookies and Similar Technologies:</strong> We may use cookies and similar tracking technologies to enhance your user experience and gather information about your preferences and activities on our website.</p>

      <p><strong>4. How We Use Your Information</strong><br/>
        We may use your information for the following purposes:<br/>
        a. <strong>To Provide and Improve Services:</strong> We use your personal information to deliver and enhance the functionality of our Services, improve user experience, and develop new features.<br/>
        b. <strong>To Process Transactions:</strong> If you engage our financial services, we use your financial information to prepare and file your tax returns and assist with other financial matters as per your request.<br/>
        c. <strong>To Communicate with You:</strong> We may use your contact information to send you updates, newsletters, and promotional materials related to our Services. You can opt-out of these communications at any time.<br/>
        d. <strong>For Legal and Compliance Purposes:</strong> We may use your information to comply with legal obligations, resolve disputes, protect our rights and interests, and enforce our policies.</p>

      <p><strong>5. How We Share Your Information</strong><br/>
        We may share your information with third parties under the following circumstances:<br/>
        a. <strong>With Your Consent:</strong> We may share your information with your explicit consent or at your request.<br/>
        b. <strong>Service Providers:</strong> We may engage third-party service providers to assist with various aspects of our Services, such as payment processing, data analysis, marketing, and customer support.<br/>
        c. <strong>Legal Requirements:</strong> We may disclose your information to comply with applicable laws, regulations, or legal processes or to respond to valid legal requests from government authorities.<br/>
        d. <strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</p>

      <p><strong>6. Your Choices and Rights</strong><br/>
        You have certain rights regarding your personal information, including:<br/>
        a. <strong>Access and Correction:</strong> You can access and update your personal information by logging into your account or contacting us directly.<br/>
        b. <strong>Opt-Out:</strong> You can opt-out of receiving promotional emails or marketing communications from us by following the instructions in those emails.<br/>
        c. <strong>Data Deletion:</strong> You can request the deletion of your personal information, subject to legal and contractual obligations.</p>

      <p><strong>7. Security</strong><br/>
        We take reasonable measures to safeguard your personal information and protect it from unauthorized access, use, or disclosure. However, no data transmission over the internet is completely secure, and we cannot guarantee the absolute security of your information.</p>

      <p><strong>8. Changes to this Privacy Policy</strong><br/>
        We may update this Privacy Policy from time to time to reflect changes in our practices or for legal, operational, or regulatory reasons. We encourage you to review this page regularly to stay informed about our privacy practices.</p>

      <p><strong>9. Contact Us</strong><br/>
        If you have any questions, concerns, or requests related to this Privacy Policy, please contact us at hi@sawingz.com. We will do our best to address your queries and resolve any issues promptly.</p>

      <p>By using our Services, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and disclosure of your information as described herein.</p>
    </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy