import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./leadingmedia.css";

const CompaneysCrousel = () => {
  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="CompaneysCrousel_main">
      <div className="CompaneysCrousel">
        <h2 className="slider_heading">As seen on leading media</h2>
        {/* <Container fluid="md">
          <Row>
            <Col> */}
              <Slider className="slider_main_parent" {...settings}>
                <div className="slider_img">
                  <img src="./Assets/abp.png" alt="abpnews-savingz" />
                </div>
                <div className="slider_img">
                  <img src="./Assets/republicnewstoday.png" alt="republicnews-savingz" />
                </div>
                <div className="slider_img">
                  <img src="./Assets/businessstandard.png" alt="businessstandard-savingz" />
                </div>
                <div className="slider_img">
                  <img src="./Assets/entrepreneurview.png" alt="entrepreneurview-savingz" />
                </div>
                <div className="slider_img">
                  <img src="./Assets/theprint.png" alt="theprint-savingz" />
                </div>
                <div className="slider_img">
                  <img src="./Assets/ani.png" alt="ani-savingz" />
                </div>
                <div className="slider_img">
                  <img src="./Assets/asiannews.png" alt="asiannews-savingz" />
                </div>
                <div className="slider_img">
                  <img src="./Assets/financialpost.png" alt="financialpost-savingz" />
                </div>
                
              </Slider>
              <div className="slider_down_div"></div>
            {/* </Col>
          </Row>
        </Container> */}
      </div>
    </div>
  );
};

export default CompaneysCrousel;
