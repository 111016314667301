import React from "react";
import WhoCard from "./WhoCard";

const MasterclassFor = ({ taxMasterclass }) => {
  const masterclassFor = taxMasterclass
    ? [
        {
          emoji: "🙋‍♂️",
          heading: "Freshers",
          para: "Learn the foundations of Indian Tax Ecosystem early in your career. This will give you a head start in planning your taxes.",
        },
        {
          emoji: "🧑‍💻",
          heading: "Working professionals",
          para: "As a working professional you don't have time to learn from boring articles and long youtube videos",
        },
      ]
    : [
        {
          emoji: "🙋‍♂️",
          heading: "Freshers",
          para: "Learn the foundations of Indian Tax Ecosystem early in your career. This will give you a head start in planning your taxes.",
        },
        {
          emoji: "🧑‍💻",
          heading: "Working professionals",
          para: "As a working professional you don't have time to learn from boring articles and long youtube videos",
        },
        {
          emoji: "🧑‍💼",
          heading: "Business Owners",
          para: "As a business owner you need to understand how to plan personal finance along with business finance. Do not mix the two.",
        },
      ];

  return (
    <div className="masterclass-for-section">
      <div className="learn-heading_wrapper">
        <h2>Who's this webinar for?</h2>
      </div>
      <div className="masterclass-for-grid">
        {masterclassFor.map(({ heading, para, emoji }, index) => (
          <WhoCard key={index} heading={heading} para={para} emoji={emoji} />
        ))}
      </div>
    </div>
  );
};

export default MasterclassFor;
