import React from "react";
import { FaCalendar } from "react-icons/fa";
import { MdAccessTimeFilled } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Hero = ({ taxMasterclass }) => {
  const heading1 = taxMasterclass ? "Tax Filing" : "Wealth Planning";
  const heading2 = taxMasterclass ? "Made Simple" : "Made Simple";

  const para1 = taxMasterclass
    ? "with CA Aanchal Minda, (9+ years exp.)"
    : "with Manish Singh, (10+ years exp.)";
  const para2 = taxMasterclass
    ? "Stay ahead in the game and plan your taxes efficiently"
    : "Learn the science behind wealth planning and easily achieve all your financial goals";

  const span = taxMasterclass ? "and save your taxes!" : "with peace of mind.";
  const dateAndTime = taxMasterclass ? " 28th July, 3 PM" : "20th July, 3PM";
  const duration = taxMasterclass ? " 1 Hour" : "1 Hour";

  const heroImg = taxMasterclass
    ? "Assets/hero-img.png"
    : "Assets/wealth-planning-hero.png";

  const navigate = useNavigate();
  const redirectToPayment = () => {
    const navigateTo = taxMasterclass
      ? "TaxMasterclass 28th July, 3 PM"
      : "EquityMasterclass 20th July, 4 PM";
    navigate(
      `/${
        taxMasterclass ? "masterclass" : "equity-masterclass"
      }?campaignId=" + ${navigateTo}`
    );
  };

  return (
    <div className="hero-section">
      <div className="hero-section-text">
        <h1 className="hero-heading">{heading1}</h1>
        <h1 className="hero-heading made-simple-text">{heading2}</h1>
        <p className="with-text">{para1}</p>
        <p className="hero-section-para">
          {para2}
          <span> {span}</span>
        </p>
        <div className="border-gradient">
          <div>
            <p className="border-gradient-text">
              <FaCalendar color="#7c22ba" size={25} />{" "}
              <span>{dateAndTime}</span>
            </p>
            <p className="border-gradient-text">
              <MdAccessTimeFilled color="#7c22ba" size={25} />{" "}
              <span>{duration}</span>
            </p>
          </div>
        </div>
        <button className="book-button" onClick={redirectToPayment}>
          Book Your Seat
        </button>
        <div className="sold-out">
          <div class="progress-bar">
            <div class="progress"></div>
          </div>
          <p class="percentage-label">
            <span>84.7%</span> sold out!
          </p>
        </div>
      </div>
      <div className="hero-image-wrapper">
        <img src={heroImg} alt="CA Anchal" />
      </div>
    </div>
  );
};

export default Hero;
