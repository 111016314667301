// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_backdrop__QqzYk {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
}

.modal_modal__EGTRG {
  z-index: 10;
  position: fixed;
  top: 20vh;
  left: calc(50% - 17rem);
  background-color: white;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  animation: modal_slide-down__vszmJ 300ms ease-out forwards;
}

@keyframes modal_slide-down__vszmJ {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 800px) {
  .modal_modal__EGTRG {
    top: 10vh;
    left: calc(50% - 12.5rem);
    /* overflow-y: scroll; */
  }
}

@media screen and (max-width: 475px) {
  .modal_modal__EGTRG {
    position: fixed;
    top: 30vh;
    left: calc(50% - 9.5rem);
    /* overflow-y: scroll;
    overflow-x: hidden; */
  }
}
`, "",{"version":3,"sources":["webpack://./src/RentReceipt/Modal/modal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,eAAe;EACf,MAAM;EACN,OAAO;EACP,oCAAoC;EACpC,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,eAAe;EACf,SAAS;EACT,uBAAuB;EACvB,uBAAuB;EACvB,mBAAmB;EACnB,yCAAyC;EACzC,0DAA6C;AAC/C;;AAEA;EACE;IACE,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,SAAS;IACT,yBAAyB;IACzB,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,eAAe;IACf,SAAS;IACT,wBAAwB;IACxB;yBACqB;EACvB;AACF","sourcesContent":[".backdrop {\n  height: 100vh;\n  width: 100vw;\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: rgba(0, 0, 0, 0.7);\n  z-index: 9;\n}\n\n.modal {\n  z-index: 10;\n  position: fixed;\n  top: 20vh;\n  left: calc(50% - 17rem);\n  background-color: white;\n  border-radius: 14px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n  animation: slide-down 300ms ease-out forwards;\n}\n\n@keyframes slide-down {\n  from {\n    opacity: 0;\n    transform: translateY(-3rem);\n  }\n  to {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n\n@media screen and (max-width: 800px) {\n  .modal {\n    top: 10vh;\n    left: calc(50% - 12.5rem);\n    /* overflow-y: scroll; */\n  }\n}\n\n@media screen and (max-width: 475px) {\n  .modal {\n    position: fixed;\n    top: 30vh;\n    left: calc(50% - 9.5rem);\n    /* overflow-y: scroll;\n    overflow-x: hidden; */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `modal_backdrop__QqzYk`,
	"modal": `modal_modal__EGTRG`,
	"slide-down": `modal_slide-down__vszmJ`
};
export default ___CSS_LOADER_EXPORT___;
