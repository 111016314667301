import axios from "axios";
import { toast } from "react-hot-toast";

export const generateRentReceipts = (values) => {
  const start = new Date(values.startDate);
  const end = new Date(values.endDate);
  const rentPerDay = values.rent / 30;

  let yearDiff = end.getFullYear() - start.getFullYear();
  let monthDiff = end.getMonth() - start.getMonth();
  let daysDiff = end.getDate() - start.getDate();

  if (daysDiff < 0) {
    monthDiff--;
    daysDiff += new Date(end.getFullYear(), end.getMonth(), 0).getDate();
  }

  let totalMonths = yearDiff * 12 + monthDiff;

  let receipts = [];
  for (let i = 0; i <= totalMonths; i++) {
    const param = new Date(
      start.getFullYear(),
      start.getMonth() + i,
      start.getDate()
    );
    let receiptDate = new Date(
      start.getFullYear(),
      start.getMonth() + i,
      start.getDate()
    );
    let nextDate;
    let rent = values.rent;

    if (
      param.getFullYear() === end.getFullYear() &&
      param.getMonth() === end.getMonth()
    ) {
      nextDate = end;
      rent = Math.ceil(daysDiff * rentPerDay);
    } else {
      nextDate = new Date(
        start.getFullYear(),
        start.getMonth() + i + 1,
        start.getDate() - 1
      );
    }

    if (rent === 0) break;
    else {
      const receipt = {
        receiptDate,
        nextDate,
        rent,
        receiptNo: i + 1,
      };

      receipts.push(receipt);
    }
  }

  return receipts;
};

export const sendOtp = async (phoneNumber, setShowModal) => {
  try {
    const { data } = await axios.post(
      "https://seahorse-app-69qkl.ondigitalocean.app/send-otp",
      {
        phoneNumber: phoneNumber,
      }
    );
    toast.success("OTP successfully sent!");
    setShowModal(true);
    if (data.id) {
      localStorage.setItem("savingzOtpId", JSON.stringify(data.id));
    }
  } catch (error) {
    const statusCode = error?.response?.data?.status || error?.response?.status;
    if (statusCode && statusCode === 410) setShowModal(true);
    else setShowModal(false);
    const err = error?.response?.data?.detail || "Something went wrong!";
    toast.error(err);
  }
};

export const verifyOtp = async (otp, closeModal, setError, setIsVerified) => {
  try {
    const id = JSON.parse(localStorage.getItem("savingzOtpId"));
    if (!id) {
      closeModal();
      return;
    }
    await axios.post(
      "https://seahorse-app-69qkl.ondigitalocean.app/verify-otp",
      {
        id,
        otp,
      }
    );
    toast.success("You're verified user!");
    setIsVerified(true);
    closeModal();
    const section = document.getElementById("rent_receipts");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    localStorage.removeItem("savingzOtpId");
  } catch (error) {
    const statusCode = error?.response?.data?.status || error?.response?.status;
    const err = error?.response?.data?.detail
      ? error?.response?.data?.detail
      : error?.response?.detail
      ? error?.response?.detail
      : "Something went wrong!";

    if (statusCode && statusCode === 403) setError(err);
    else {
      closeModal();
      toast.error(err);
    }
  }
};
