import React from "react";
import Banner from "../Banner/Banner";
import CompaneysCrousel from "../TrustedCompaneys/CompaneysCrousel";
import Pricing from "../Pricing";
import WhyChooseUs from "../WhyChoose/WhyCooseUs";
import Features from "../Features/Features";
import FeatureSlider from "../FeatureSlider/FeatureSlider";
import Stats from "../Stats/Stats";
import Testimonials from "../Testimonials/Testimonials";
import TaxCalculator from "../Taxcal/Taxcal";
import Team from "../Team/Team";
import ContactUS from "../ContactUs/ContactUS";
import LeadingMedia from "../LeadingMedia/LeadingMedia";
import { useSearchParams } from "react-router-dom";
import MasterclassPayment from "../Common/Masterclass/MasterclassPayment";

const HomePage = () => {
  const [searchParams] = useSearchParams();

  if (searchParams.get("premiumPlan")) {
    const cost = searchParams.get("premiumPlan");
    const heading =
      Number(cost) === 4500
        ? "Smart Taxpayer"
        : Number(cost) === 5700
        ? "Smart Taxpayer Plus"
        : "Elite Pro Taxpayer";
    return (
      <MasterclassPayment
        payment={cost}
        heading={`Register for ${heading}`}
        goBackUrl="/"
      />
    );
  }

  return (
    <>
      <Banner />
      <CompaneysCrousel />
      <Pricing />
      <div id="whychooseus">
        <WhyChooseUs />
      </div>
      <LeadingMedia />

      <div className="desktop-only" id="products">
        <Features />
      </div>
      <div className="mobile-only" id="products">
        <FeatureSlider />
      </div>
      <Stats />
      <div id="testimonials">
        <Testimonials />
      </div>
      <TaxCalculator />
      <Team />
      <ContactUS />
    </>
  );
};

export default HomePage;
