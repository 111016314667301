// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stats_inner_main_div {
    display: flex;
    align-items: center;
    padding: 20px;
    
    border-radius: 8px; /* Optional: rounds the corners of each stat box */
    margin: 10px 0; /* Optional: adds space between stat boxes */
  }
  
  .stats_icon {
    margin-right: 15px;

  }
  
  .stats_text {
    display: flex;
    flex-direction: column;
  }
  
  .stats_h1 {
font-family: Sora;
font-size: 32px;
font-weight: 600;
line-height: 44px;
text-align: left;

  }
  
  .stats_p {
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: left;

  }
  
  @media screen and (max-width: 768px) {
  .stats_inner_main_div{
    align-items: center;
    text-align: center;
    justify-content: center;
  }
    .stats_h1{
font-family: Sora;
font-size: 32px;
font-weight: 600;
line-height: 44px;
text-align: left;

    }
    .stats_p{
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: left;

    }
    
  }`, "",{"version":3,"sources":["webpack://./src/Stats/stats.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;;IAEb,kBAAkB,EAAE,kDAAkD;IACtE,cAAc,EAAE,4CAA4C;EAC9D;;EAEA;IACE,kBAAkB;;EAEpB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;AACF,iBAAiB;AACjB,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;;EAEd;;EAEA;AACF,kBAAkB;AAClB,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;;EAEd;;EAEA;EACA;IACE,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;EACzB;IACE;AACJ,iBAAiB;AACjB,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;;IAEZ;IACA;AACJ,kBAAkB;AAClB,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;;IAEZ;;EAEF","sourcesContent":[".stats_inner_main_div {\n    display: flex;\n    align-items: center;\n    padding: 20px;\n    \n    border-radius: 8px; /* Optional: rounds the corners of each stat box */\n    margin: 10px 0; /* Optional: adds space between stat boxes */\n  }\n  \n  .stats_icon {\n    margin-right: 15px;\n\n  }\n  \n  .stats_text {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .stats_h1 {\nfont-family: Sora;\nfont-size: 32px;\nfont-weight: 600;\nline-height: 44px;\ntext-align: left;\n\n  }\n  \n  .stats_p {\nfont-family: Inter;\nfont-size: 14px;\nfont-weight: 400;\nline-height: 24px;\ntext-align: left;\n\n  }\n  \n  @media screen and (max-width: 768px) {\n  .stats_inner_main_div{\n    align-items: center;\n    text-align: center;\n    justify-content: center;\n  }\n    .stats_h1{\nfont-family: Sora;\nfont-size: 32px;\nfont-weight: 600;\nline-height: 44px;\ntext-align: left;\n\n    }\n    .stats_p{\nfont-family: Inter;\nfont-size: 14px;\nfont-weight: 400;\nline-height: 24px;\ntext-align: left;\n\n    }\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
