import React, { useState } from "react";
import "./contactus.css";
import hiphone from "./hiphone.png";
import downld from "./download.png";
import headfon from "./headset.png";
import dblack from "./Vector.png";
import Model from "./model"; // Ensure this is the correct path

const ContactUS = () => {
  const [dimg, setdimg] = useState(downld);
  const [showDialog, setShowDialog] = useState(false); // State to control the dialog visibility

  const mouseover = () => {
    setdimg(dblack);
  };

  const mouseout = () => {
    setdimg(downld);
  };

  const handleDownloadClick = (e) => {
    e.preventDefault(); // Prevent any default action
    setShowDialog(true);
  };

  const openPopup = () => {
    window.Calendly.showPopupWidget(
      "https://calendly.com/savingz/free-ca-call-clone"
    );
  };

  return (
    <>
      <div className="contactus_main">
        <div className="contactus">
          <div className="contactus_outer">
            <div className="contactus_inner_left">
              <h2>Contact us</h2>
              <p>
                We are always looking forward to hear about problems, ideas and
                many more
              </p>
              <div className="banner_btn_div">
                <button className="dn_btn" onClick={openPopup}>
                  Free CA call{""}
                  <img
                    src={headfon}
                    alt="Inr png"
                    style={{ marginLeft: "5px" }}
                  />
                </button>

                <button
                  onMouseOver={mouseover}
                  onMouseOut={mouseout}
                  onClick={handleDownloadClick}
                  className="dn_btn_two"
                >
                  Download App{" "}
                  <img src={dimg} alt="Inr png" style={{ marginLeft: "5px" }} />
                </button>
              </div>
            </div>
            <div className="contactus_inner_right">
              <div>
                <img
                  className="contactus_customimage_one"
                  src={hiphone}
                  alt="CustomOne"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDialog && <div className="blur-background"></div>}
      <Model show={showDialog} onHide={() => setShowDialog(false)} />
    </>
  );
};

export default ContactUS;
