import React from "react";
import { FaStar } from "react-icons/fa";

const Review = ({ review }) => {
  return (
    <div className="review">
      <div className="review-heading">
        <div className="review-heading-left">
          <img src={review.reviewerImage} alt="" />
          <div className="review-heading-left-content">
            <h3>{review.reviewerName}</h3>
            <p>{review.position}</p>
          </div>
        </div>
        <div className="review-heading-right">
          <FaStar color="#7c22ba" size={25} />
          <span>{review.rating}/5</span>
        </div>
      </div>
      <p className="review-paragraph">{review.review}</p>
    </div>
  );
};

export default Review;
