import React, { useEffect, useState } from 'react';

const TypingAndAlternatingText = ({ words, typingSpeed = 150, deletingSpeed = 75, delayBetweenWords = 1000 }) => {
    const [currentText, setCurrentText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [wordIndex, setWordIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);

    useEffect(() => {
        if (words.length === 0) return; // Early exit if no words provided

        let timer;

        if (isDeleting) {
            // Delete the whole text at once after a delay
            timer = setTimeout(() => {
                setCurrentText('');  // Clear the text
                setIsDeleting(false);
                setCharIndex(0); // Reset character index
                setWordIndex((wordIndex + 1) % words.length); // Move to the next word
            }, delayBetweenWords);
        } else {
            // Type out characters one by one
            if (charIndex < words[wordIndex].length) {
                timer = setTimeout(() => {
                    setCurrentText(current => current + words[wordIndex][charIndex]);
                    setCharIndex(charIndex + 1);
                }, typingSpeed);
            } else {
                // After typing the full word, set isDeleting to true
                timer = setTimeout(() => {
                    setIsDeleting(true);
                }, delayBetweenWords);
            }
        }

        return () => clearTimeout(timer);
    }, [isDeleting, charIndex, wordIndex, words, typingSpeed, deletingSpeed, delayBetweenWords]);

    return (
        <span className="typed-text" aria-live="polite" aria-atomic="true">
            {currentText}
        </span>
    );
};

export default TypingAndAlternatingText;
