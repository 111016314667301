import React from "react";
import "./tnc.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RippleButton from "../RippleButton/RippleButton";

const Cancellation = () => {
  const openPopup = () => {
    window.Calendly.showPopupWidget(
      "https://calendly.com/savingz/free-ca-call-clone"
    );
  };
  return (
    <>
      <div className="tnc_main">
        <div className="tnc">
          <h2>Cancellation</h2>

          <Row>
            <Col className="tnc_mainsec">
              <div className="tnc_leftsec">
                <h4>Savings made simple</h4>
                <p>
                  Don’t know where to start from? It's simple, click on that
                  button and wait for our call, let's take it step by step. We
                  are here to help people like you get rich and achieve their
                  goals.
                </p>
                <RippleButton onClick={openPopup} className="tnc_btn">
                  CONTACT US<i className="fi fi-rr-arrow-right arrow"></i>
                </RippleButton>
              </div>
              <div className="tnc_rightsec">
                <img src="./Assets/gandhiji.png" alt="" />
              </div>
            </Col>
            <Col className="tnc_secondsec"></Col>
          </Row>
          <div className="terms-and-conditions">
          <p><strong>Effective Date: 28/06/2023</strong></p>

<p>At Savingz (Sawingz Solution Private Limited), we strive to provide exceptional service and customer satisfaction. If for any reason you wish to cancel your engagement with us, we offer a hassle-free cancellation and refund process.</p>

<p><strong>Cancellation:</strong></p>
<ul>
    <li><p>For tax optimization services: You may cancel your engagement with us at any time before the commencement of the tax filing process. To initiate the cancellation, please contact our customer support team at <a href="mailto:contact@sawingz.com">contact@sawingz.com</a> or call <a href="tel:+917892037507">+91 78920 37507</a>.</p></li>
    <li><p>For tax filing services: If you decide to cancel after the tax filing process has started, you may do so within 24 hours of providing all necessary information and documents.</p></li>
</ul>

<p><strong>Refund:</strong></p>
<ul>
    <li><p>Tax optimization services: In case of cancellation before the commencement of the tax filing process, you will be entitled to a full refund.</p></li>
    <li><p>Tax filing services: If you cancel within 24 hours of providing all necessary information and documents, you will receive a partial refund, deducting the processing fees and the work already completed.</p></li>
</ul>

<p>Please note that any refund will be processed using the same payment method used during the initial purchase.</p>

<p>For any further questions or assistance regarding our cancellation and refund policy, please don't hesitate to contact our support team. We value your trust and satisfaction with our services and are committed to ensuring a seamless experience for all our users.</p>

    </div>
        </div>
      </div>
    </>
  )
}

export default Cancellation