import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useMedia } from "react-use";

const Learn = ({ item, index }) => {
  const isMobile = useMedia("(max-width: 475px)");
  const control = useAnimation();
  const [ref, inView] = useInView();

  const leftBoxVariants = isMobile
    ? {
        visible: {
          x: 0,
          opacity: 1,
          transition: { duration: 0.5, ease: "easeOut" },
        },
        hidden: {
          x: -50,
          opacity: 0,
        },
      }
    : {
        visible: {
          x: 0,
          opacity: 1,
          transition: { duration: 0.5, ease: "easeOut" },
        },
        hidden: {
          x: -100,
          opacity: 0,
        },
      };

  const rightBoxVariants = isMobile
    ? {
        visible: {
          x: 0,
          opacity: 1,
          transition: { duration: 0.5, ease: "easeOut" },
        },
        hidden: {
          x: 50,
          opacity: 0,
        },
      }
    : {
        visible: {
          x: 0,
          opacity: 1,
          transition: { duration: 0.5, ease: "easeOut" },
        },
        hidden: {
          x: 100,
          opacity: 0,
        },
      };

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <div className={`learning_card ${(index + 1) % 2 === 0 && "reversed"}`}>
      <div className="learning_card-image-wrapper">
        <motion.img
          ref={ref}
          variants={
            !isMobile
              ? (index + 1) % 2 === 0
                ? rightBoxVariants
                : leftBoxVariants
              : leftBoxVariants
          }
          initial="hidden"
          animate={control}
          src={item.image}
          alt=""
          className="learning_card-image"
        />
      </div>
      <motion.div
        ref={ref}
        variants={
          !isMobile
            ? (index + 1) % 2 === 0
              ? leftBoxVariants
              : rightBoxVariants
            : rightBoxVariants
        }
        initial="hidden"
        animate={control}
        className="learning_card-content-wrapper"
      >
        <div className="learning_card-content">
          <span className="time_text">{item.time}</span>
          <h2 className="learning_card-heading">{item.heading}</h2>
          <p className="learning_card-para">{item.para}</p>
        </div>
      </motion.div>
    </div>
  );
};

export default Learn;
