import React, { useState } from "react";
import { motion } from "framer-motion";

const InputBox = ({
  inputValue,
  setInputValue,
  inputError,
  setInputError,
  id,
  label,
  type,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="inputBox">
      <label className="label" htmlFor={id}>
        {label}
      </label>
      <motion.input
        variants={{
          visible: {
            height: "3rem",
          },
          hidden: { height: "auto" },
        }}
        initial={false}
        className={`input ${isExpanded && "input_height"}`}
        value={inputValue}
        onBlur={() => setIsExpanded(() => false)}
        onFocus={() => setIsExpanded(() => true)}
        onChange={(e) => {
          setInputError("");
          setInputValue(e.target.value);
        }}
        id={id}
        type={type}
      />
      {inputError && <p className="inputError">{inputError}</p>}
    </div>
  );
};

export default InputBox;
