import React, { useEffect, useReducer, useState } from "react";
import "./rent-receipt-generator.css";
import Form1 from "./Form1";
import Form2 from "./Form2";
import RentReceipts from "./RentReceipts";
import Verification from "./Verification";
import VerificationModal from "./Modal/VerificationModal";
import { generateRentReceipts, sendOtp } from "./actions";

const initials = {
  rent: "",
  address: "",
  name: "",
  number: "",
  houseOwnerName: "",
  houseOwnerPan: "",
  startDate: "",
  endDate: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RENT":
      return { ...state, rent: action.value };
    case "ADDRESS":
      return { ...state, address: action.value };
    case "NAME":
      return { ...state, name: action.value };
    case "NUMBER":
      return { ...state, number: action.value };
    case "HOUSEOWNERNAME":
      return { ...state, houseOwnerName: action.value };
    case "HOUSEOWNERPAN":
      return { ...state, houseOwnerPan: action.value };
    case "STARTDATE":
      return { ...state, startDate: action.value };
    case "ENDDATE":
      return { ...state, endDate: action.value };
    default:
      return state;
  }
};

const RentReceiptGenerator = () => {
  const [values, valuesDispatch] = useReducer(reducer, initials);
  const [errors, errorsDispatch] = useReducer(reducer, initials);
  const [formIndex, setFormIndex] = useState(1);
  const [rentReceipts, setRentReceipts] = useState([]);
  const [isVerified, setIsVerified] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const generate = async () => {
    setIsVerified(false);
    const receipts = generateRentReceipts(values);

    setRentReceipts(receipts);

    await sendOtp(values.number, setShowModal);
  };

  useEffect(() => {
    if (isVerified) {
      const section = document.getElementById("rent_receipts");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [rentReceipts, isVerified]);

  return (
    <div className="container">
      <div className="form_container">
        <div>
          <h4>Generate free Rent Receipts</h4>
          <ul>
            <li>Avoid last minute hassle of arranging rent receipts</li>
            <li>Fill the required details</li>
            <li>Print the receipt in PDF Format</li>
            <li>Get the receipt signed from your landlord</li>
            <li>Submit it to your company's HR department</li>
          </ul>
        </div>
        {formIndex === 1 && (
          <Form1
            forwardIndex={() => setFormIndex(2)}
            values={values}
            valuesDispatch={valuesDispatch}
            errors={errors}
            errorsDispatch={errorsDispatch}
          />
        )}
        {formIndex === 2 && (
          <Form2
            backwardIndex={() => setFormIndex(1)}
            values={values}
            generateRentReceipts={generate}
            valuesDispatch={valuesDispatch}
            errors={errors}
            errorsDispatch={errorsDispatch}
          />
        )}
      </div>
      {showModal && (
        <VerificationModal
          setIsVerified={setIsVerified}
          onClose={() => setShowModal(false)}
          values={values}
          showModal={() => setShowModal(true)}
        />
      )}
      {isVerified && rentReceipts && rentReceipts.length > 0 && (
        <RentReceipts values={values} rentReceipts={rentReceipts} />
      )}
    </div>
  );
};

export default RentReceiptGenerator;
