import React from "react";
import { Link } from "react-router-dom";
import { FaWhatsapp, FaLinkedin, FaInstagram } from "react-icons/fa";
import "./footertwo.css";

const Footertwo = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  };

  return (
    <div className="footer-main">
      <div className="footer">
        <div className="footer-section">
          <div className="footer-leftsec">
            <img src="./Assets/savingz.png" alt="logo" />
            <p>
              Not having access to Financial Advisors should never be the reason
              for your financial burden. We at Savingz are proud to live by our
              motto, Finance Simplified.
            </p>
            <div className="footer_social_icons">
              <a href="https://in.linkedin.com/company/savingz">
                <FaLinkedin className="footer_icon" size={20} />
              </a>
              <a href="https://wa.me/918608600419?text=Hi%20Team,%20Wanted%20to%20talk%20about%20something">
                <FaWhatsapp className="footer_icon" size={20} />
              </a>
              <a href="https://www.instagram.com/savingz.club">
                <FaInstagram className="footer_icon" size={20} />
              </a>
            </div>
          </div>
          <div className="footer-links company">
            <h5>Company</h5>
            <Link to="/" onClick={scrollToTop}>
              Home
            </Link>
            <Link to="/about-us" onClick={scrollToTop}>
              About Us
            </Link>
            <Link to="/privacy-policy" onClick={scrollToTop}>
              Privacy Policy
            </Link>
            <Link to="/terms-and-conditions" onClick={scrollToTop}>
              Terms & Conditions
            </Link>
            <Link to="/cancellation" onClick={scrollToTop}>
              Compliance
            </Link>
          </div>
          <div className="footer-links services">
            <h5>Our Services</h5>
            <Link to="/itr-filing" onClick={scrollToTop}>
              Tax Optimisation
            </Link>
            <Link to="/wealth-management" onClick={scrollToTop}>
              Wealth Management
            </Link>
            <Link to="/alternative-investment" onClick={scrollToTop}>
              Alternate Investment
            </Link>
            <Link to="/itr-filing" onClick={scrollToTop}>
              AI Finance
            </Link>
          </div>
          <div className="footer-contact">
            <h5>Contact Information</h5>
            <p>
              62/1, 9th Main Rd, Stage, Indiranagar, Bengaluru North, Bangalore-
              560038
            </p>
            <p>8608600419</p>
            <p>hi@savingz.in</p>
            <p>pr@savingz.in</p>
          </div>
        </div>
        <div className="footer-copyright">
          <h4>SAVINGZ IS A PRODUCT OF ©2024 SAWINGZ SOLUTIONS PRIVATE LIMITED. All rights reserved.</h4>
        </div>
      </div>
    </div>
  );
};

export default Footertwo;
