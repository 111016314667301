import React from "react";

const RentReceipt = ({
  name,
  startDate,
  endDate,
  receiptNo,
  rent,
  houseOwnerPan,
  houseOwnerName,
  address,
  rentReceiptsLength,
}) => {
  function formatDate(date, isHeading) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = isHeading
      ? String(date.getDate() + 1).padStart(2, "0")
      : String(date.getDate()).padStart(2, "0"); // Ensure two digits for day
    const month = months[date.getMonth()]; // Get month name from the array
    const year = date.getFullYear(); // Get full year

    return `${month} ${day} ${year}`;
  }

  return (
    <div
      id={`receipt_${receiptNo}`}
      className={`rent_receipt print-body ${
        receiptNo === 1 || receiptNo % 3 === 1 ? "printable-content" : ""
      }`}
    >
      <div className="rent_receipt_header">
        <div>
          <p className="rent_receipt_para">
            Rent Receipt{" "}
            <span>
              {`${startDate.toLocaleString("default", { month: "long" })} 
              ${startDate.getFullYear()}`}
            </span>
          </p>
          <p>Generated on Savingz</p>
        </div>
        <div>
          <p>Receipt No: {receiptNo}</p>
          <p>
            Date:{" "}
            {formatDate(
              endDate,
              rentReceiptsLength === receiptNo ? false : true
            )}
          </p>
        </div>
      </div>
      <p>
        Received sum of INR <span>Rs.{rent}</span> from <span>{name}</span>{" "}
        towards the rent of property located at <span>{address}</span> for the
        period from <span>{formatDate(startDate)}</span> to{" "}
        <span>{formatDate(endDate, false)}</span>
      </p>
      <div className="rent_receipt_footer">
        <span>{houseOwnerName}</span>
        <p>(Landlord)</p>
        {houseOwnerPan && <span>Pan: {houseOwnerPan}</span>}
      </div>
    </div>
  );
};

export default RentReceipt;
