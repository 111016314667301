import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./model.css";
import RippleButton from "../RippleButton/RippleButton";
import { IoLogoAndroid } from "react-icons/io";
import { FaApple } from "react-icons/fa";

function Model(props) {
  useEffect(() => {
    const body = document.querySelector("body");
    if (props.show) {
      body.classList.add("blur-background");
    } else {
      body.classList.remove("blur-background");
    }
    return () => {
      body.classList.remove("blur-background");
    };
  }, [props.show]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="modal_body d-flex flex-column justify-content-center align-items-center text-center">
        <img src="./Assets/qr.png" alt="qr" />
        <div>
          <a
            href="https://apps.apple.com/th/app/savingz-taxes-and-investment/id6475763801"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <RippleButton className="model_btn download_btn">
              <div>
                <FaApple size={30} color="#681b8c" />
                <div>
                  <p className="download_text">Download on the</p>
                  <span>App Store</span>
                </div>
              </div>
              {/* <img src="./Assets/appstoreicon.png" alt="" /> */}
            </RippleButton>
          </a>
          <a
            href="https://savz.live/androidapp"
            // target="_blank"
            style={{ textDecoration: "none" }}
          >
            <RippleButton className="model_btn download_btn">
              <div>
                <IoLogoAndroid size={30} color="#681b8c" />
                <div>
                  <p className="download_text">Download on the</p>
                  <span>Android</span>
                </div>
              </div>
            </RippleButton>
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Model;
