import React from "react";
import FAQ from "./FAQ";

const FAQSection = () => {
  const faqs = [
    {
      question: "How will I get the link to attend the Webinar?",
      answer:
        "Your unique Google Meet link will be sent to the email address and WhatsApp number you provided upon registration. There will be pre-Webinar reminders sent out as well. One individual can only join using a single link, so be careful not to share it with anyone. ",
    },
    {
      question: "The Webinar will be conducted in which language?",
      answer: ` It will be conducted in simple English and Hindi language.`,
    },
    {
      question: "Do I need to prepare anything before the webinar?",
      answer: `No prior preparation is needed. Just get ready to learn and take notes. If you have specific questions, you may want to jot them down to ask during the Q&A session.`,
    },
    {
      question: "Will there be a Q&A session?",
      answer:
        "Yes, there will be a Q&A session towards the end of the webinar where you can ask any questions you have about tax planning.",
    },
    {
      question:
        "Where can I go to get help either before or after registering?",
      answer:
        "We will assist you immediately if you email us at hi@savingz.in using your registered email address.",
    },
  ];

  return (
    <div className="faq-section">
      <div className="learn-heading_wrapper">
        <h2>FAQs</h2>
      </div>
      <div className="faq-list">
        {faqs.map((item, index) => (
          <FAQ key={index} faq={item} />
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
