import React from "react";
import "../../Common/Masterclass/masterclass.css";
import Hero from "../../Common/Masterclass/Hero";
import NumbersSection from "../../Common/Masterclass/NumbersSection";
import ReviewsSection from "../../Common/Masterclass/ReviewsSection";
import PotentialSection from "../../Common/Masterclass/PotentialSection";
import LearnSection from "../../Common/Masterclass/LearnSection";
import MasterclassFor from "../../Common/Masterclass/MasterclassFor";
import FeaturedOnSection from "../../Common/Masterclass/FeaturedOnSection";
import FAQSection from "../../Common/Masterclass/FAQSection";
import { useSearchParams } from "react-router-dom";
import MasterclassPayment from "../../Common/Masterclass/MasterclassPayment";

const EquityWebinar = () => {
  const [searchParams] = useSearchParams();

  if (searchParams.get("campaignId")) {
    return (
      <MasterclassPayment
        goBackUrl="/equity-masterclass"
        heading="Register for Equity Webinar"
      />
    );
  }

  return (
    <div className="container">
      <Hero />
      <NumbersSection />
      <ReviewsSection />
      <PotentialSection />
      <LearnSection />
      <MasterclassFor />
      <FeaturedOnSection />
      <FAQSection />
    </div>
  );
};

export default EquityWebinar;
