import React from "react";

const MoneyItem = ({ item }) => {
  return (
    <div className="money-grid-item">
      <img src={item.img} alt="" className="money-grid-item-img-wrapper" />
      <div className="money-grid-item-content">
        <h3 className="money-grid-item-heading">{item.heading}</h3>
        <p className="money-grid-item-paragraph">{item.para}</p>
      </div>
    </div>
  );
};

export default MoneyItem;
