import React from "react";
import "./tnc.css";
import RentReceiptGenerator from "../RentReceipt/RentReceiptGenerator";

const RentReceipt = () => {
  return (
    <div
      style={{
        marginTop: "60px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "80%",
      }}
    >
      <RentReceiptGenerator />
    </div>
  );
};

export default RentReceipt;
