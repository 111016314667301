import React, { useState } from 'react';
import './phonedialog.css'; // Ensure this CSS file contains the styling similar to the banner dialog

const PhoneDialog = ({ onClose, onSend }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');

  const handleSendClick = () => {
    if (validatePhoneNumber(phoneNumber)) {
      const formattedPhoneNumber = `91${phoneNumber}`;
      onSend(formattedPhoneNumber);
      setError('');
    } else {
      setError('Please enter a valid 10-digit phone number.');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendClick();
    }
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[6-9]\d{9}$/; // Indian phone number regex
    return phoneRegex.test(number);
  };

  const handleOverlayClick = (e) => {
    if (e.target.className === 'phone-dialog-overlay') {
      onClose();
    }
  };

  return (
    <div className="phone-dialog-overlay" onClick={handleOverlayClick}>
      <div className="phone-dialog">
        <div className="phone-dialog-content">
          <input
            type="tel"
            placeholder="Enter your phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            onKeyDown={handleKeyDown}
            className="phone-input"
          />
          <button className="send-button" onClick={handleSendClick}>
            <img src={require('./link_black.png')} alt="Send" className="send-icon" />
          </button>
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default PhoneDialog;
