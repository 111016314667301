// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-successful-heading {
  margin-top: 5rem;
  /* margin-bottom: -1rem; */
}

.register-successful-heading span {
  font-weight: 900;
  color: #7511d3;
}

.register-successful-heading span {
  font-weight: 900;
  color: #7511d3;
}

.register-successful-text {
  margin-top: 1rem;
  font-weight: 700;
}

@media screen and (max-width: 475px) {
  .go_back_btn {
    margin-top: 3rem !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/MasterclassRegisterSuccess/masterclass-register-successful.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".register-successful-heading {\n  margin-top: 5rem;\n  /* margin-bottom: -1rem; */\n}\n\n.register-successful-heading span {\n  font-weight: 900;\n  color: #7511d3;\n}\n\n.register-successful-heading span {\n  font-weight: 900;\n  color: #7511d3;\n}\n\n.register-successful-text {\n  margin-top: 1rem;\n  font-weight: 700;\n}\n\n@media screen and (max-width: 475px) {\n  .go_back_btn {\n    margin-top: 3rem !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
