import React from "react";
import "./tnc.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RippleButton from "../RippleButton/RippleButton";

const WealthManagement = () => {
  const openPopup = () => {
    window.Calendly.showPopupWidget(
      "https://calendly.com/savingz/free-ca-call-clone"
    );
  };
  return (
    <>
      <div className="tnc_main">
        <div className="tnc">
          <h2>Wealth Management</h2>

          <Row>
            <Col className="tnc_mainsec">
              <div className="tnc_leftsec">
                <h4>Savings made simple</h4>
                <p>
                  Don’t know where to start from? It's simple, click on that
                  button and wait for our call, let's take it step by step. We
                  are here to help people like you get rich and achieve their
                  goals.
                </p>
                <RippleButton onClick={openPopup} className="tnc_btn">
                  CONTACT US<i className="fi fi-rr-arrow-right arrow"></i>
                </RippleButton>
              </div>
              <div className="tnc_rightsec">
                <img src="./Assets/gandhiji.png" alt="" />
              </div>
            </Col>
            <Col className="tnc_secondsec"></Col>
          </Row>
          <div className="terms-and-conditions">
            <p>
              At Savingz, we use proprietary AI algorithms to help you manage
              your finances smarter. Our platform gives you personalized advice
              and suggestions tailored to your financial goals and how much risk
              you're comfortable with when investing. But we don't stop there,
              we offer comprehensive wealth management services designed to
              optimize your financial portfolio. Our approach includes a
              thorough evaluation of your portfolio, providing personalized
              advice on funds, equity, estate planning, and various other
              aspects of wealth management. Our distinctive 360° investment
              guidance, unmatched in the market, sets us apart. Our wealth
              management services include:
            </p>

            <p>
              <strong>1. Initial Advisory Call:</strong> We begin by
              understanding your financial goals, risk tolerance, and investment
              preferences through an initial advisory call.
            </p>
            <p>
              <strong>2. Portfolio Evaluation:</strong> Our team conducts a
              comprehensive evaluation of your existing portfolio, identifying
              strengths, weaknesses, and opportunities for improvement.
            </p>
            <p>
              <strong>3. Goal-Based Planning:</strong> We work with you to
              establish clear financial goals and develop a customized wealth
              management plan tailored to your objectives.
            </p>
            <p>
              <strong>4. Diversification Strategy:</strong> Utilizing our
              expertise and market insights, we implement a diversification
              strategy to minimize risk and maximize returns across your
              investment portfolio.
            </p>
            <p>
              With Savingz, you can trust that your wealth is in capable hands.
              Our commitment to personalized advice and strategic planning
              ensures that your financial future is secure and prosperous.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WealthManagement;
